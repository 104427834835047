import { FC } from 'react';
import { ActionIcon, Loader, ThemeIcon, Tooltip } from '@mantine/core';
import {
  IconExclamationMark,
  IconPlayerPlay,
  IconPlayerSkipForward,
  IconPlayerStop,
  IconUserCheck,
} from '@tabler/icons';
import { WorkflowOperation, WorkflowStepDTO } from '../../Types/LogT';

const StepExecutionStatusIcon: FC<{
  step: WorkflowStepDTO;
  currentStep: boolean;
  runOperation: (operation: WorkflowOperation) => void;
  notStarted?: boolean;
}> = ({ step, currentStep, runOperation, notStarted = false }) => {
  const { executionReport, procedureType, executionConfig } = step;
  const { thresholdDate } = executionConfig;
  const { executionStatus } = executionReport ?? {};
  const failed = executionStatus === 'FAILED' || executionStatus === 'PRECONDITION_FAILED';

  if (procedureType === 'WAIT_UNTIL' && currentStep) {
    return null;
  }

  if (notStarted && currentStep) {
    return (
      <Tooltip label="Workflow starten">
        <ActionIcon color="green" radius="xl" onClick={() => runOperation('START')}>
          <IconPlayerPlay size={16} />
        </ActionIcon>
      </Tooltip>
    );
  }

  if (failed && currentStep) {
    return (
      <Tooltip label="Noch mal versuchen">
        <ThemeIcon color="red" radius="xl" onClick={() => runOperation('RETRY')}>
          <IconExclamationMark size={16} />
        </ThemeIcon>
      </Tooltip>
    );
  }

  if (executionStatus === 'APPROVAL_REQUIRED') {
    return (
      <Tooltip label="Schritt genehmigen">
        <ActionIcon color="orange" radius="xl" onClick={() => runOperation('APPROVE')}>
          <IconUserCheck size={16} />
        </ActionIcon>
      </Tooltip>
    );
  }

  if (executionStatus === 'PAUSED') {
    return (
      <Tooltip label="Workflow fortzuführen">
        <ActionIcon color="orange" radius="xl" onClick={() => runOperation('CONTINUE')}>
          <IconPlayerSkipForward size={16} />
        </ActionIcon>
      </Tooltip>
    );
  }

  if (currentStep && executionStatus !== 'COMPLETED' && thresholdDate) {
    return (
      <Tooltip label="Workflow abbrechen">
        <ActionIcon color="red" radius="xl" onClick={() => runOperation('PAUSE')}>
          <IconPlayerStop size={16} color="red" />
        </ActionIcon>
      </Tooltip>
    );
  }
  if (currentStep && executionStatus !== 'ABORTED') {
    return <Loader variant="dots" size="sm" />;
  }
  return null;
};

export default StepExecutionStatusIcon;
