import { useEffect, useState } from 'react';
import { Contact, ContactS } from '../ContactS';

// eslint-disable-next-line import/prefer-default-export
export const useContacts = () => {
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);

  useEffect(() => {
    setLoading(true);
    ContactS.fetchAll()
      .then(setContacts)
      .finally(() => setLoading(false));
  }, []);

  return { loading, contacts };
};
