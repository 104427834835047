// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Space, Text } from '@mantine/core';
import { useContext } from 'react';
import BookingStateItem from '../BookingStateItem';
import { EventsContext } from '../../../Service/Context/EventsContext';

const DateIntervalSelection = () => {
  const { filter, updateFilter } = useContext(EventsContext);
  const { interval } = filter;

  return (
    <>
      <Space h="xl" />
      <Text pl={10}>Datumsbereich</Text>
      <Space h="xs" />
      <BookingStateItem
        label="Alle Events"
        onClick={() => updateFilter({ interval: 'ALL_TIME' })}
        active={interval === 'ALL_TIME'}
      />
      <BookingStateItem
        label="Letztes Jahr"
        onClick={() => updateFilter({ interval: 'LAST_YEAR' })}
        active={interval === 'LAST_YEAR'}
      />
      <BookingStateItem
        label="Dieses Jahr"
        onClick={() => updateFilter({ interval: 'CURRENT_YEAR' })}
        active={interval === 'CURRENT_YEAR'}
      />
      <BookingStateItem
        label="Letzter Monat"
        onClick={() => updateFilter({ interval: 'LAST_MONTH' })}
        active={interval === 'LAST_MONTH'}
      />
      <BookingStateItem
        label="Dieser Monat"
        onClick={() => updateFilter({ interval: 'CURRENT_MONTH' })}
        active={interval === 'CURRENT_MONTH'}
      />
      <BookingStateItem
        label="Nächste Events"
        onClick={() => updateFilter({ interval: 'FUTURE' })}
        active={interval === 'FUTURE'}
      />
      <BookingStateItem
        label="Nächster Monat"
        onClick={() => updateFilter({ interval: 'NEXT_MONTH' })}
        active={interval === 'NEXT_MONTH'}
      />
    </>
  );
};

export default DateIntervalSelection;
