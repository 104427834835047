import { BankAccountDTO } from '../Types/AuthT';
import { Contact } from './ContactS';
import { Profile } from './ProfileS';

const AWS_SPECIAL_CHARS_REGEX = /["/\\]/;
const TEMPLATE_NAME_SPECIAL_CHARS = /[/?#:%&+]/;
const LEITWEG_REGEX = /^[0-9]{4,8}(-[0-9]{1,4}){2,4}$/;

const validInvoiceRouteId = (str: string) => LEITWEG_REGEX.test(str);

const validAwsObjectKeyName = (str: string) => !AWS_SPECIAL_CHARS_REGEX.test(str);

const validateAwsContactProps = (contact: Partial<Contact>) => {
  const { firstName, lastName } = contact;
  if (firstName && !validAwsObjectKeyName(firstName)) {
    return false;
  }
  if (lastName && !validAwsObjectKeyName(lastName)) {
    return false;
  }
  return true;
};

const validatePortalPassword = (str: string) => !/[#&]/g.test(str);

const validateTemplateName = (str: string) => !TEMPLATE_NAME_SPECIAL_CHARS.test(str);

const verifyBankAccount = (bankAccount: BankAccountDTO) => {
  const { iban, bic } = bankAccount;
  return isDefined(iban) && isDefined(bic);
};

const verifyTaxFields = (profile: Profile) => {
  const { vatId, taxNumber, smallScaleBusiness } = profile;
  if (smallScaleBusiness) {
    return isDefined(taxNumber);
  }
  return isDefined(vatId) && isDefined(taxNumber);
};

const verifyAddressInformation = (contact: Contact) => {
  const { addressStreetAndNo, city, zipCode } = contact;
  return isDefined(addressStreetAndNo) && isDefined(city) && isDefined(zipCode);
};

export const isDefined = (str: string | null | undefined) => typeof str === 'string' && str.trim() !== '';

// eslint-disable-next-line import/prefer-default-export
export const ValidationS = {
  validAwsObjectKeyName,
  validateAwsContactProps,
  validatePortalPassword,
  validateTemplateName,
  validInvoiceRouteId,
  verifyBankAccount,
  verifyTaxFields,
  verifyAddressInformation,
};
