import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_FILE_SERVICE_URL;

// Expend later as needed
export type EntityType = 'resources' | 'documents' | 'contracts' | 'questionnaires' | 'profiles';

type FileDetails = {
  fileName: string;
  fileSize: number;
  lastModified: Date;
};

const put = async (file: File, entityType: EntityType, entityId = 'default'): Promise<boolean> => {
  const res = await ajaxActions.putFile(`${BASE_URL}/files/${entityType}/${entityId}`, file);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const getFirstEntityFile = async (entityType: EntityType, entityId: string): Promise<Blob | null> => {
  const files = await getEntityFiles(entityType, entityId);
  if (files.length > 0) {
    return get(entityType, entityId, files[0].fileName);
  }
  return null;
};

const get = async (entityType: EntityType, entityId: string, fileName: string): Promise<Blob | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/files/${entityType}/${entityId}/${fileName}`);
  if (res.ok) {
    return res.blob();
  }
  ToastS.generalError();
  return null;
};

const getFirstEntityFileDetails = async (entityType: EntityType, entityId: string): Promise<FileDetails | null> => {
  const fileDetails = await getEntityFiles(entityType, entityId);
  return fileDetails ? fileDetails[0] : null;
};

const getEntityFiles = async (entityType: EntityType, entityId: string): Promise<FileDetails[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/files/${entityType}/${entityId}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return [];
};

const removeEntityFiles = async (entityType: EntityType, entityId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/files/${entityType}/${entityId}`);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const remove = async (entityType: EntityType, entityId: string, fileName: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/files/${entityType}/${entityId}/${fileName}`);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const getFileSize = (fileSizeInBytes: number) => {
  if (fileSizeInBytes >= 1000000000) {
    return [Math.floor(fileSizeInBytes / 1000000000), 'GB'];
  }
  if (fileSizeInBytes >= 1000000) {
    return [Math.floor(fileSizeInBytes / 1000000), 'MB'];
  }
  if (fileSizeInBytes >= 1000) {
    return [Math.floor(fileSizeInBytes / 1000), 'KB'];
  }
  return [fileSizeInBytes, 'Bytes'];
};

const isFileExists = async (entityType: EntityType, entityId: string, fileName: string): Promise<boolean> => {
  const res = await ajaxActions.get(`${BASE_URL}/files/${entityType}/${entityId}/${fileName}/exists`);
  return res.ok;
};

const downloadFile = async (entityType: EntityType, entityId: string, fileName: string) => {
  const fileBlob = await get(entityType, entityId, fileName);
  if (fileBlob) {
    // Create a link element
    const link = document.createElement('a');
    // Create a URL for the Blob and set it as the href
    const url = window.URL.createObjectURL(fileBlob);
    link.href = url;
    // Set the download attribute with the file name
    link.download = fileName;
    // Make sure the link is not visible and doesn't affect the DOM
    link.style.display = 'none';
    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const FileS = {
  get,
  put,
  remove,
  removeEntityFiles,
  getEntityFiles,
  getFirstEntityFileDetails,
  getFirstEntityFile,
  getFileSize,
  isFileExists,
  downloadFile,
};
