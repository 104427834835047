import { useEffect, useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { Trans, useLingui } from '@lingui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { Paper, Space, Text } from '@mantine/core';
import { fetchRecommendation, patchRecommendationState } from '../../Service/restapi/recommendationService';
import { LoadingAnimation } from '../../Service/ResourceAdapter';
import { fetchEventInfosById } from '../../Service/restapi/eventService';
import BookingStateBadge from '../../Molecules/BookingStateBadge';
import { TransButtonDefault, TransButtonPrimary, TransButtonPrimaryWithSpinner } from '../../Atoms/Buttons';
import { ModalDialog, useModalState } from '../../Molecules/Dialogs';
import { Icon } from '../../Atoms/Icons';
import { RoutingS } from '../../Service/RoutingS';
import RecommendationAnswer from './RecommendationAnswer';
import { EventS } from '../../Service/EventS';
import { StringUtils } from '../../Utils/utils';

const RecommendationRequest = ({ isAppUser }) => {
  const navigate = useNavigate();
  const { id: recommendationId } = useParams();
  const [recommendation, setRecommendation] = useState(undefined);
  const [event, setEvent] = useState(undefined);
  const [optionalMessage, setOptionalMessage] = useState(undefined);
  const [bookingState, setBookingState] = useState(recommendation ? recommendation.bookingState : undefined);
  const [initialBookingState, setInitialBookingState] = useState(
    recommendation ? recommendation.bookingState : undefined,
  );
  const [loading, setLoading] = useState(false);
  const { show, toggle } = useModalState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [recommendationNotFound, setRecommendationNotFound] = useState(false);

  useEffect(() => {
    loadRecommendation();
  }, [recommendationId]);

  function loadRecommendation() {
    fetchRecommendation(recommendationId).then((resp) => {
      if (resp.ok) {
        resp.json().then(setRecommendation);
      } else if (resp.status === 404) {
        setRecommendationNotFound(true);
      }
    });
  }

  useEffect(() => {
    if (recommendation) {
      fetchEventInfosById(recommendation.eventId).then((resp) => {
        if (resp.ok) {
          resp.json().then((eventForRecommendation) => {
            // eslint-disable-next-line no-param-reassign
            eventForRecommendation.dates = eventForRecommendation.dates.filter((date) =>
              recommendation.eventDatesIds.includes(date.id),
            );
            setEvent(eventForRecommendation);
          });
        }
      });
      setBookingState(recommendation.bookingState);
      setInitialBookingState(recommendation.bookingState);
    }
  }, [recommendation]);

  function patchState() {
    setLoading(true);
    patchRecommendationState(recommendationId, bookingState, optionalMessage)
      .then((resp) => {
        if (resp.ok) {
          loadRecommendation();
          setOptionalMessage(undefined);
          toggle();
          setInitialBookingState(bookingState);
          setSuccess(true);
          setError(false);
        } else {
          console.error(
            `could not patch recommendation ${recommendationId} by state ${bookingState} and optionalMessage:`,
            { optionalMessage },
          );
          setError(true);
          setSuccess(false);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(
          `could not patch recommendation ${recommendationId} by state ${bookingState} and optionalMessage:`,
          { optionalMessage },
        );
        setSuccess(false);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const printifyDates = () => {
    const mapped = event.dates.map((d) => EventS.formatEventDateWithDay(new Date(d.date)));
    return StringUtils.concatStrings(mapped);
  };

  return (
    <Paper
      shadow="xs"
      p={40}
      sx={{
        maxWidth: 480,
        borderRadius: 10,
        position: 'relative',
        backgroundClip: 'padding-box, border-box',
        backgroundImage: 'linear-gradient(#fff,#fff),linear-gradient(88deg,#ffba5e,#ff6b69)',
        borderTop: '10px double transparent',
      }}
    >
      <Row>
        <br />

        <Card>
          {(!recommendation || !event) && !recommendationNotFound && (
            <CardBody style={{ minHeight: 200 }}>
              <LoadingAnimation />
              <div style={{ textAlign: 'center', marginTop: 20 }}>Vermittlung wird geladen...</div>
            </CardBody>
          )}
          {recommendationNotFound && (
            <CardBody style={{ minHeight: 100 }}>
              <h6 style={{ textAlign: 'center' }}>Die Vermittlung wurde entfernt</h6>
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                Bitte wende Dich an den Dienstleister von dem Du diese Anfrage erhalten hast.
              </div>
            </CardBody>
          )}
          {recommendation && event && (
            <CardBody>
              {event && <h3>{printifyDates()}</h3>}
              <div className="description">Empfehlung erhalten von {recommendation.sourceProfileAlias}</div>
              <br />
              <div className="step">
                <RecommendationAnswer
                  id={recommendationId}
                  sourceProfileAlias={recommendation.sourceProfileAlias}
                  reloadRecommendation={loadRecommendation}
                  savedAnswer={recommendation.answer}
                  lastAnswered={recommendation.lastAnswered}
                />
              </div>
              <br />
              {recommendation.answer === 'AVAILABLE' && recommendation.lastContactShare && (
                <div className="step haspredecessor">
                  <div style={{ marginBottom: 20 }}>
                    Dein aktueller Status:{' '}
                    {bookingState ? (
                      <Trans id={`recommendation.bookingState.${bookingState.toLowerCase()}`} />
                    ) : (
                      'Kein Kundenkontakt'
                    )}
                  </div>
                  <div className="description" style={{ marginBottom: 10 }}>
                    Aktualisiere hier jederzeit den Status der Vermittlung, um {recommendation.sourceProfileAlias} zu
                    informieren.
                  </div>
                  <TransButtonPrimary onClickHandler={toggle} text="Status ändern" />
                  {success && (
                    <div style={{ color: 'green', marginTop: 10 }}>
                      <Icon value="CHECK" /> Deine Antwort wurde erfolgreich übermittelt.
                    </div>
                  )}
                  {error && (
                    <div style={{ color: 'red', marginTop: 10 }}>
                      <Icon value="ATTENTION_TRIANGLE" /> Fehler bei Übermittlung der Statusänderung. Bitte versuche es
                      später erneut.
                    </div>
                  )}
                  <ModalDialog
                    header="Vermittlungsstatus anpassen"
                    body={
                      <div>
                        <div style={{ display: 'flex' }}>
                          <div style={{ marginRight: 10, alignSelf: 'center' }}>Status:</div>
                          <BookingStateBadge
                            recommendationState
                            bookingState={bookingState || recommendation.bookingState}
                            handleChange={setBookingState}
                          />
                        </div>
                        <textarea
                          type="text"
                          value={optionalMessage}
                          contentEditable
                          style={{ marginTop: 20 }}
                          className="form-control"
                          onChange={(e) => {
                            setOptionalMessage(e.target.value);
                          }}
                          placeholder="Optionale Nachricht"
                        />
                      </div>
                    }
                    footer={
                      <>
                        <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />
                        <TransButtonPrimaryWithSpinner
                          isLoading={loading}
                          onClickHandler={patchState}
                          text="actions.send"
                        />
                      </>
                    }
                    show={show}
                    toggle={toggle}
                  />
                </div>
              )}
            </CardBody>
          )}
        </Card>
        <br />
        {!isAppUser && (
          <>
            <Space h="xl" />
            <Text color="dimmed" align="center">
              Du hast noch keinen Account?
            </Text>
            <Text
              color="blue"
              align="center"
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate(RoutingS.ROUTES.REGISTER)}
            >
              Kostenlos registrieren
            </Text>
          </>
        )}
      </Row>
    </Paper>
  );
};

export default RecommendationRequest;
