/* eslint-disable react/jsx-props-no-spreading */
import { closeAllModals, openModal } from '@mantine/modals';
import { Button, Center, Group, Loader, Text, TextInput } from '@mantine/core';
import { FC, useRef, useState } from 'react';
import { IconBuildingCommunity, IconLocation, IconPhone } from '@tabler/icons';
import { useForm } from '@mantine/form';
import { i18n } from '@lingui/core';
import { LocationDto } from '../../../Types/LocationT';
import GoogleAutocomplete from '../../../Atoms/Autocomplete/GoogleAutocomplete';
import { LocationS } from '../../../Service/LocationS';

interface ModalProps {
  eventId: number;
  location: Partial<LocationDto>;
  onFinish: (location: LocationDto) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const openLocationModal = (props: ModalProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        {props.location.id ? 'Location bearbeiten' : 'Location erstellen'}
      </Text>
    ),
    children: <LocationModal {...props} />,
  });

const LocationModal: FC<ModalProps> = ({ eventId, location, onFinish }) => {
  const { id, name, streetAndNo, zipCode, city, phoneNumber, website } = location;
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      eventId,
      name,
      streetAndNo,
      zipCode,
      city,
      phoneNumber,
      website,
    },
  });

  const [autocompleteFocused, setAutocompleteFocused] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const onPlaceSelected = ({ alias, phoneNumber, city, addressStreetAndNo, zipCode, addressOfWebsite }: any) => {
    form.setValues({
      name: alias,
      streetAndNo: addressStreetAndNo,
      zipCode: zipCode ?? null,
      city: city ?? null,
      phoneNumber: phoneNumber ?? null,
      website: addressOfWebsite ?? null,
    });
  };

  const onSubmit = async () => {
    if (autocompleteFocused) {
      // Prevent form submit when item is selected in autocomplete through ENTER
      if (ref.current) {
        ref.current.focus();
      }
      return;
    }
    setLoading(true);
    let _location;
    if (id) {
      _location = await LocationS.editLocation(id, { ...form.values });
    } else {
      _location = await LocationS.createLocation({ ...form.values });
    }
    setLoading(false);
    if (_location) {
      onFinish(_location);
      closeAllModals();
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <GoogleAutocomplete
        icon={<IconLocation size={20} />}
        label="Location"
        placeholder="Location durchsuchen"
        onPlaceSelected={onPlaceSelected}
        {...form.getInputProps('name')}
        onBlur={() => setAutocompleteFocused(false)}
        onFocus={() => setAutocompleteFocused(true)}
      />

      <TextInput
        mt="sm"
        icon={<IconBuildingCommunity size={20} />}
        label="Straße und Hausnummer"
        {...form.getInputProps('streetAndNo')}
      />
      <Group grow spacing="xs" mt="xs" mb="xs">
        <TextInput
          label="Postleitzahl"
          onKeyPress={(event) => {
            if (!/[0-9,\.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          {...form.getInputProps('zipCode')}
        />
        <TextInput label="Stadt" {...form.getInputProps('city')} />
      </Group>
      <TextInput label="Telefonnummer" icon={<IconPhone size={20} />} {...form.getInputProps('phoneNumber')} />
      <Center mt={30}>
        <Button
          ref={ref}
          disabled={loading || !form.isDirty()}
          type="submit"
          rightIcon={loading && <Loader size="xs" />}
          sx={{ minWidth: 230 }}
        >
          {i18n._('actions.save')}
        </Button>
      </Center>
    </form>
  );
};
