import { ajaxActions } from '../AjaxActions';
import { DataS } from '../DataS';

const BASE_URL = process.env.REACT_APP_PAYMENT_SERVICE_URL;

const getSubscription = () => ajaxActions.get(`${BASE_URL}/subscriptions`).then((resp) => resp);

export const createSubscription = (planId, paymentProvider, reactivate, couponCode) =>
  ajaxActions
    .post(`${BASE_URL}/subscriptions`, {
      planId,
      paymentProvider,
      reactivate,
      couponCode,
    })
    .then((resp) => resp);

export const cancelSubscriptionAPI = (reason) =>
  ajaxActions.del(`${BASE_URL}/subscriptions`, reason).then((resp) => resp);

export const getBillingPlans = () => ajaxActions.get(`${BASE_URL}/subscriptions/plans`).then((resp) => resp);

export const changeSubscriptionPlan = (planId) =>
  ajaxActions.patch(`${BASE_URL}/subscriptions`, { planId }).then((resp) => resp);

export const deletePendingSubscription = () =>
  ajaxActions.del(`${BASE_URL}/subscriptions/pending`).then((resp) => resp);

export const isSubscriptionActivated = () => ajaxActions.get(`${BASE_URL}/subscriptions/active`).then((resp) => resp);

export const getMandates = () => ajaxActions.get(`${BASE_URL}/subscriptions/mandates`).then((resp) => resp);

export const revokeMandate = (mandateId) =>
  ajaxActions.del(`${BASE_URL}subscriptions/mandates/${mandateId}`).then((resp) => resp);

const getHubSpotIdTokenAPI = async () => {
  const resp = await ajaxActions.get(`${BASE_URL}/hubspot/visitor-token`);
  if (resp.ok) {
    const resJSON = await resp.json();
    return resJSON.token;
  }
  // eslint-disable-next-line no-console
  console.error('Unable to resolve visitor token');
  return null;
};

const validateCoupon = (code) => ajaxActions.get(`${BASE_URL}/coupon?code=${code}`).then((resp) => resp);

const updateBillingPlansViewed = () => ajaxActions.get(`${BASE_URL}/hubspot/billing-plans-viewed`).then((resp) => resp);

const cancelSubscription = (reason) => ajaxActions.del(`${BASE_URL}/subscriptions`, reason).then((resp) => resp);

const migrateToChargebee = () =>
  ajaxActions.get(`${BASE_URL}/subscriptions/migrate-to-chargebee`).then((resp) => resp.ok);

const getAddonTier = (quantity) => DataS.billingAddons[0].price.Monthly.find((tier) => quantity <= tier.to);

const SubscriptionS = {
  validateCoupon,
  updateBillingPlansViewed,
  getSubscription,
  cancelSubscription,
  migrateToChargebee,
  getAddonTier,
  getHubSpotIdTokenAPI,
};

export default SubscriptionS;
