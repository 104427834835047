import { UseListStateHandlers } from '@mantine/hooks';
import { FC } from 'react';
import { IconBallpen, IconCheck, IconDotsVertical, IconPlugConnected, IconTrash, IconX } from '@tabler/icons';
import { Menu, ThemeIcon, Tooltip } from '@mantine/core';
import { i18n } from '@lingui/core';
import { ExternalSubscription } from '../../../../Types/CalDavT';
import Dot from '../../../../Atoms/Dot';
import { TableResponsiveProps } from '../../../../Types/ResourceT';
import { CalDavS } from '../../../../Service/CalDavS';
import { openDeleteExternalCalModal, openEditExternalCalModal } from './ExternalSubscriptionModals';

interface Props extends TableResponsiveProps {
  index: number;
  externalSubscription: ExternalSubscription;
  handlers: UseListStateHandlers<ExternalSubscription>;
}

const ExternalCalendarItem: FC<Props> = ({ index, externalSubscription, handlers, showCalUrl, showClashesIcon }) => {
  const { id, name, color, subscriptionUrl, healthy, checkForClashes } = externalSubscription;
  const { remove, setItem, setItemProp } = handlers;

  const removeHandler = () =>
    openDeleteExternalCalModal({ subscription: externalSubscription, onDelete: () => remove(index) });

  const editHandler = () =>
    openEditExternalCalModal({
      subscription: externalSubscription,
      onChange: (val) => setItem(index, val),
    });

  const retryConnection = async () => {
    const reconnected = await CalDavS.retryConnection(id);
    if (reconnected) {
      setItemProp(index, 'healthy', true);
    }
  };

  return (
    <tr>
      <td align="center">
        <Tooltip
          multiline
          width={200}
          label={i18n._(`externalSubscription.status.${healthy ? 'healthy' : 'unhealthy'}`)}
          transition="slide-up"
        >
          <ThemeIcon radius="xl" size="sm" color={healthy ? 'blue' : 'red'}>
            {healthy ? <IconCheck size={14} /> : <IconX size={14} />}
          </ThemeIcon>
        </Tooltip>
      </td>
      {showClashesIcon && (
        <td align="center">
          <Tooltip multiline width={200} label="Fragen Sie den Kalender nach Verfügbarkeit ab." transition="slide-up">
            <ThemeIcon radius="xl" size="sm" color={checkForClashes ? 'blue' : 'gray'}>
              {checkForClashes ? <IconCheck size={14} /> : <IconX size={14} />}
            </ThemeIcon>
          </Tooltip>
        </td>
      )}
      <td align="center">
        <Dot withoutShadow colour={color ?? 'red'} />
      </td>
      <td>{name}</td>
      {showCalUrl && (
        <td className="overflow-ellipsis" style={{ maxWidth: 300 }}>
          {subscriptionUrl}
        </td>
      )}
      <td style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', right: 0, bottom: 10 }}>
          <Menu shadow="md" transition="scale-y" position="bottom-end">
            <Menu.Target>
              <div>
                <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              {!healthy && (
                <Menu.Item icon={<IconPlugConnected size={16} />} onClick={retryConnection}>
                  Erneut verbinden
                </Menu.Item>
              )}
              {healthy && (
                <Menu.Item icon={<IconBallpen size={16} />} onClick={editHandler}>
                  {i18n._('actions.edit')}
                </Menu.Item>
              )}
              <Menu.Item color="red" icon={<IconTrash size={16} />} onClick={removeHandler}>
                {i18n._('actions.delete')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </td>
    </tr>
  );
};

export default ExternalCalendarItem;
