import { useContext, useEffect, useState } from 'react';
import { Box, LoadingOverlay, Space, Switch } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useLingui } from '@lingui/react';
import DocumentHeadData from '../../Molecules/DocumentForm';
import DocumentSkeleton from '../../Organismns/Document/DocumentSkeleton';
import { DocumentContext } from '../../Service/Context/DocumentContext';
import { getContactById } from '../../Service/restapi/contactService';
import PanePaper from '../../Molecules/PanePaper';
import DocumentActions from '../../Molecules/Document/DocumentActions';
import DocumentHeadTemplate from '../DocumentHeadTemplate';
import { useXRechnungValidator } from '../../Service/hooks/useXRechnungValidator';
import { useContact } from '../../Service/hooks/useContact';
import { DocumentS } from './DocumentS';
import { isDefined } from '../../Service/ValidationS';

const DocumentForm = () => {
  const { i18n } = useLingui();
  const { document, handleChange, addPosition, savePatch } = useContext(DocumentContext);
  const { id: documentId, eventId, customerId } = document;
  const [contact, setContact] = useState();
  const navigate = useNavigate();
  const emptyPositions = document && (!document.positions || document.positions.length === 0);
  const { contact: customer, editContact } = useContact(customerId);
  const { valid } = useXRechnungValidator(document, customer);

  useEffect(() => {
    if (documentId && eventId) {
      navigate(`/events/${eventId}/documents/${documentId}`);
    }
  }, [documentId, eventId]);

  useEffect(() => {
    getContactById(document.customerId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setContact);
        } else {
          console.error(`could not load contact ${document.customerId}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load contact ${document.customerId}`);
      });
  }, [document.customerId]);

  const documentType = i18n._(`document.${document.documentType.toLowerCase()}`);

  return (
    <PanePaper>
      <DocumentHeadTemplate
        entityId={document.id}
        entityType="documents"
        title={`${documentType} erstellen`}
        previewUrl={
          eventId ? `/events/${eventId}/documents/${document.id}/preview` : `/documents/${document.id}/preview`
        }
        documentActions={<DocumentActions />}
        disablePreview={emptyPositions || !valid}
        // eInvoiceSwitch={
        //   DocumentS.isInvoice(document.documentType) &&
        //   customer && (
        //     <Switch
        //       label="E-Rechnung"
        //       checked={isDefined(customer.buyerReference)}
        //       onChange={(e) => {
        //         editContact({ buyerReference: e.target.checked ? '0' : null });
        //         handleChange({ xrechnungRequired: e.target.checked });
        //       }}
        //     />
        //   )
        // }
      />
      <LoadingOverlay visible={!contact} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Space h="md" />
      <Box p="xl">
        <DocumentHeadData customer={customer} />
        <DocumentSkeleton
          _document={document}
          customer={contact}
          handleChange={handleChange}
          addPosition={addPosition}
          savePatch={savePatch}
        />
      </Box>
    </PanePaper>
  );
};

export default DocumentForm;
