import { useEffect, useState } from 'react';
import { Contact, ContactS } from '../ContactS';

// eslint-disable-next-line import/prefer-default-export
export const useContact = (contactId: number | null) => {
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<Contact | null>(null);

  useEffect(() => {
    if (contactId) {
      setLoading(true);
      ContactS.fetchById(contactId)
        .then(setContact)
        .finally(() => setLoading(false));
    }
  }, [contactId]);

  const editContact = async (patch: Partial<Contact>) => {
    if (contact) {
      const resJSON = await ContactS.edit(contact.id, patch);
      if (resJSON) {
        setContact(resJSON);
      }
    }
  };

  return { loading, contact, editContact };
};
