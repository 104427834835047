// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Center, Checkbox, Group, SegmentedControl, Select, Slider, Space, Text, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { SelectField } from '../../../Atoms/InputFields';
import PDFViewer from '../../../Atoms/PDFViewer';
import { DocumentLayout, PdfS } from '../../../Service/PdfS';
import { DataS } from '../../../Service/DataS';
import PanePaper from '../../../Molecules/PanePaper';
import { TestS } from '../../../TestS';
import { RoutingS } from '../../../Service/RoutingS';
import MantineColorPicker from '../../../Atoms/inputs/MantineColorPicker';
import { IconChevronDown, IconLetterCase, IconTypography } from '@tabler/icons';

const DocumentLayoutSettings: React.FC<{
  lastModified: Date;
  logoAvailable: boolean;
}> = ({ lastModified, logoAvailable }) => {
  const [documentLayout, setDocumentLayout] = useState<DocumentLayout>(PdfS.DefaultDocumentLayout);
  const {
    logoSize,
    logoPosition,
    hideLogoOnDocuments,
    infoBoxPlacement,
    contactName,
    showFounderPersonalName,
    pageNumberPosition,
    fontColor,
    backgroundColor,
    backgroundTransparent,
    showBorders,
    showLineNumber,
    hideBankAccount,
  } = documentLayout;

  const [logoPositionSlider, setLogoPositionSlider] = useState<number>(logoPosition);

  useEffect(() => {
    RoutingS.changeTitle('Document-Layout');
    PdfS.getDocumentLayout().then((layout) => {
      if (layout) {
        setDocumentLayout(layout);
      }
    });
  }, []);

  const patchDocumentLayout = (key: string, value: unknown) => {
    const patch = { [key]: value };
    PdfS.editDocumentLayout(documentLayout.id, patch).then((layout) => {
      if (layout) {
        setDocumentLayout(layout);
      }
    });
  };

  const styles = {
    color: {
      marginRight: 10,
    },
    colors: {
      display: 'flex',
    },
  };

  const selectedFontValue = DataS.fontOptions.find((o) => o.value === documentLayout.font);

  return (
    <PanePaper title="Layout">
      <Row>
        <Col sm={12} md={12} lg={5}>
          <Row>
            {logoAvailable && (
              <Col xs={12} sm={6} md={12}>
                <Card body>
                  <Title order={5}> Logo </Title>
                  <Space h="xs" />
                  <Text size="xs" color="dimmed">
                    Größe
                  </Text>
                  <Space h="xs" />
                  <SegmentedControl
                    data={DataS.logoSizeOptions}
                    value={logoSize}
                    onChange={(val) => patchDocumentLayout(PdfS.Layout.LOGO_SIZE, val)}
                    mb={20}
                    data-testid={TestS.DocumentLayout.CONTROL_LOGO_SIZE}
                  />
                  <Text size="xs" color="dimmed">
                    Position
                  </Text>
                  <Space h="xs" />
                  <Slider
                    min={-260}
                    max={540}
                    value={logoPositionSlider}
                    onChange={setLogoPositionSlider}
                    onChangeEnd={(val: number) => patchDocumentLayout(PdfS.Layout.LOGO_POSITION, val)}
                    marks={[
                      { value: -100, label: '20%' },
                      { value: 140, label: '50%' },
                      { value: 380, label: '80%' },
                    ]}
                    mb={20}
                    data-testid={TestS.DocumentLayout.SLIDER_LOGO_POSITION}
                  />
                  <Space h="xl" />
                  <Checkbox
                    label={<Text> Logo nur für Online-Portal verwenden</Text>}
                    checked={hideLogoOnDocuments}
                    onChange={(e) => patchDocumentLayout(PdfS.Layout.HIDE_LOGO, e.currentTarget.checked)}
                    mb={10}
                    data-testid={TestS.DocumentLayout.TOGGLE_LOGO_ONLY_FOR_ONLINE_PORTAL}
                  />
                </Card>
                <Space h="xs" />
              </Col>
            )}
            <Col xs={12} sm={6} md={12}>
              <Card body>
                <Title order={5}>Profilinfos</Title>
                <Space h="xs" />
                <Text size="xs" color="dimmed">
                  Wo sollen Deine Infos angezeigt werden?
                </Text>
                <Space h="xs" />
                <Center>
                  <SegmentedControl
                    orientation="vertical"
                    data={DataS.boxPlacementOptions}
                    value={infoBoxPlacement}
                    onChange={(val) => patchDocumentLayout(PdfS.Layout.BOX_PLACEMENT, val)}
                    data-testid={TestS.DocumentLayout.CONTROL_INFO_BOX_PLACEMENT}
                  />
                </Center>
                <Space h="xl" />
                <Text size="xs" color="dimmed">
                  Namensangaben
                </Text>
                <Space h="xs" />
                <SegmentedControl
                  data={DataS.getContactNameOptions(infoBoxPlacement)}
                  value={contactName}
                  onChange={(val: string) => patchDocumentLayout(PdfS.Layout.CONTACT_NAME, val)}
                  data-testid={TestS.DocumentLayout.CONTROL_CONTACT_NAME_PREFERENCE}
                />
                <Space h="xl" />
                <Text size="xs" color="dimmed">
                  Angaben zur/zum Inhaber:in
                </Text>
                <Space h="xs" />
                <Checkbox
                  checked={showFounderPersonalName}
                  label={<Text> Inhaber:in namentlich anzeigen</Text>}
                  mb={10}
                  onChange={(e) => patchDocumentLayout(PdfS.Layout.SHOW_FOUNDER_NAME, e.currentTarget.checked)}
                  data-testid={TestS.DocumentLayout.TOGGLE_SHOW_FOUNDER_PERSONAL_NAME}
                />
                <Text size="xs" color="dimmed">
                  Bankverbindung
                </Text>
                <Space h="xs" />
                <Checkbox
                  checked={hideBankAccount}
                  label={<Text> Bankverbindung nur auf Rechnungen anzeigen</Text>}
                  mb={10}
                  onChange={(e) => patchDocumentLayout(PdfS.Layout.HIDE_BANK_ACCOUNT, e.currentTarget.checked)}
                  data-testid={TestS.DocumentLayout.TOGGLE_HIDE_BANK_ACCOUNT}
                />
              </Card>
            </Col>
          </Row>
          <Space h="xs" />
          <Row>
            <Col sm={6} md={12}>
              <Card body>
                <Title order={5}> Stile </Title>
                <Space h="xl" />
                <Text size="xs" color="dimmed">
                  Justiere Deine Seitennummer
                </Text>
                <Space h="xs" />
                <SegmentedControl
                  data={DataS.pageNumberPositionOptions}
                  value={pageNumberPosition}
                  onChange={(val: string) => patchDocumentLayout(PdfS.Layout.PAGE_POSITION, val)}
                  data-testid={TestS.DocumentLayout.CONTROL_PAGE_NUMBER_POSITION}
                />
                <Space h="xs" />
                <Select
                  label="Schriftart"
                  icon={<IconLetterCase size={20} />}
                  data={DataS.fontOptions}
                  value={selectedFontValue?.value}
                  onChange={(val: string) => patchDocumentLayout(PdfS.Layout.FONT, val)}
                  data-testid={TestS.DocumentLayout.SELECT_FONT}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  rightSection={<IconChevronDown size={16} />}
                  rightSectionWidth={35}
                />
                <Space h="md" />
                <Group>
                  <MantineColorPicker
                    format="hex"
                    withPicker={false}
                    size="xl"
                    swatches={DataS.monochromeColors}
                    color={fontColor}
                    label="Schrift-Farbe"
                    onChange={(val: string) => patchDocumentLayout(PdfS.Layout.COLOR, val)}
                  />
                  <MantineColorPicker
                    format="hex"
                    withPicker={false}
                    size="xl"
                    swatches={DataS.monochromeColors}
                    color={backgroundColor}
                    label="Hintergrund-Farbe"
                    onChange={(val: string) => patchDocumentLayout(PdfS.Layout.BACKGROUND_COLOR, val)}
                  />
                </Group>
                <Space h="xl" />
                <Checkbox
                  label={<Text>Positionsnummer anzeigen</Text>}
                  checked={showLineNumber}
                  onChange={(e) => patchDocumentLayout(PdfS.Layout.SHOW_LINE_NUMBER, e.currentTarget.checked)}
                  mb={10}
                  data-testid={TestS.DocumentLayout.TOGGLE_POSITION_NUMBERS}
                />
                <Checkbox
                  label={<Text>Rahmen anzeigen</Text>}
                  checked={showBorders}
                  onChange={(e) => patchDocumentLayout(PdfS.Layout.SHOW_BORDER, e.currentTarget.checked)}
                  mb={10}
                  data-testid={TestS.DocumentLayout.TOGGLE_SHOW_BORDERS}
                />
                <Checkbox
                  label={<Text>Transparenter Hintergrund</Text>}
                  checked={backgroundTransparent}
                  onChange={(e) => patchDocumentLayout(PdfS.Layout.BACKGROUND_TRASPARENT, e.currentTarget.checked)}
                  mb={10}
                  data-testid={TestS.DocumentLayout.TOGGLE_TRANSPARENT_BACKGROUND}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12} lg={7}>
          <PDFViewer
            url={`${process.env.REACT_APP_PDF_SERVICE_URL}/documentLayouts/sample`}
            documentLayout={documentLayout}
            lastModified={lastModified}
          />
        </Col>
      </Row>
    </PanePaper>
  );
};

export default DocumentLayoutSettings;
