import { useContext } from 'react';
import { useLingui } from '@lingui/react';
import { Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import PanePaper from '../../../../Molecules/PanePaper';
import BookitupAddIcon from '../../../../Atoms/BookitupAddIcon';
import { ViolationContext } from '../../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../../Service/LicenceS';
import SkeletonItems from '../../../../Atoms/SkeletonItems';
import EmptyText from '../../../../Atoms/EmptyText';
import BookitupTable from '../../../../Atoms/BookitupTable';
import { ExternalSubscription } from '../../../../Types/CalDavT';
import { useExternalSubscriptions } from '../../../../Service/hooks/useExternalSubscriptions';
import BookitupTutorialIcon from '../../../../Atoms/BookitupTutorialIcon';
import ExternalCalendarItem from './ExternalCalendarItem';
import { openCreateExternalCalModal, openEditExternalCalModal } from './ExternalSubscriptionModals';

const ExternalSubscriptions = () => {
  const { i18n } = useLingui();
  const { ref, width } = useElementSize();
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { loading, subscriptions, handlers } = useExternalSubscriptions();
  const { append, applyWhere } = handlers;
  const { length } = subscriptions;
  const columnsVisibility = {
    showCalUrl: width > 500,
    showClashesIcon: width > 700,
  };

  const onCreate = (iCal: ExternalSubscription) => {
    append(iCal);
    openEditExternalCalModal({
      subscription: iCal,
      onChange: (sub) => {
        applyWhere(
          (item) => item.id === sub.id,
          () => sub,
        );
      },
    });
  };

  const addPrivateCalendar = () => {
    const addCalendar = () => openCreateExternalCalModal({ onCreate });
    if (length > 0) {
      executeWithLicenceCheck(LicenceS.Restrictions.PRIVATE_CALENDAR, addCalendar);
    } else {
      addCalendar();
    }
  };

  return (
    <div ref={ref}>
      <PanePaper
        title="settings.calendar.external-subscriptions"
        tooltip="help-settings-external-subscriptions-pane"
        rightSection={
          <>
            <BookitupTutorialIcon
              videoUrl="https://app.bookitup.de/academy/channels/automatic-responses?episodeId=faiabsidch"
              name="ConnectExternalCalendar"
            />
            <BookitupAddIcon onClick={addPrivateCalendar} />
          </>
        }
      >
        <Text mb="xl">{`${i18n._('external-subscription.headline')} ${i18n._(
          'external-subscription.description',
        )}`}</Text>
        {loading && <SkeletonItems />}
        {!loading && length === 0 && <EmptyText />}
        {!loading && length > 0 && (
          <BookitupTable>
            <thead>
              <tr>
                <th style={{ width: 50 }}> Status </th>
                {columnsVisibility.showClashesIcon && <th style={{ width: 100 }}>Kollisionsprüfung</th>}
                <th style={{ width: 50 }}> </th>
                <th style={{ width: 200 }}>Name</th>
                {columnsVisibility.showCalUrl && <th> Kalender-URL</th>}
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((sub, idx) => (
                <ExternalCalendarItem
                  key={sub.id}
                  index={idx}
                  handlers={handlers}
                  externalSubscription={sub}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...columnsVisibility}
                />
              ))}
            </tbody>
          </BookitupTable>
        )}
      </PanePaper>
    </div>
  );
};

export default ExternalSubscriptions;
