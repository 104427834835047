/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, LoadingOverlay, Radio, Select, Text } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { FC, useEffect, useState } from 'react';
import { IconCalendarEvent } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { DocumentS } from '../../../Templates/Documents/DocumentS';
import BookitupDocument from '../../../Types/BookitupDocument';
import { EventS } from '../../../Service/EventS';
import BookingEvent from '../../../Types/Event';
import { DataS } from '../../../Service/DataS';
import { ToastS } from '../../../Service/ToastS';
import { CreateContractRequest } from '../../../Types/DocumentT';
import EmptyText from '../../../Atoms/EmptyText';

export const openCreateContractModal = (props: CreateContractRequest) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Vertrag erstellen
      </Text>
    ),
    children: <CreateContractModal {...props} />,
  });

const CreateContractModal: FC<CreateContractRequest> = ({ eventId, ...rest }) => {
  const [availableTemplateNames, setTemplatesNames] = useState<string[]>([]);
  const [template, selectTemplate] = useState<string>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    DocumentS.getContractTemplatesNames()
      .then(setTemplatesNames)
      .finally(() => setLoading(false));
  }, []);

  const onConfirm = async () => {
    setLoading(true);
    const resJSON = await DocumentS.createContract({ eventId, ...rest, template });
    setLoading(false);
    if (resJSON) {
      navigate(`/events/${eventId}/contracts/${resJSON.id}`);
      closeAllModals();
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Radio.Group
        withAsterisk
        spacing="xs"
        offset="md"
        orientation="vertical"
        value={template}
        onChange={selectTemplate}
        label="Vorlage auswählen:"
      >
        {availableTemplateNames.length === 0 && !loading && <EmptyText />}
        {availableTemplateNames.map((name) => (
          <Radio key={name} value={name} label={name} />
        ))}
      </Radio.Group>
      <Center mt={30}>
        <Button onClick={onConfirm} disabled={loading || !template} sx={{ width: 250 }}>
          Speichern
        </Button>
      </Center>
    </>
  );
};

interface ChangeEventProps {
  bookitupDocument: BookitupDocument;
  onChange: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const openAssignEventModal = (props: ChangeEventProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Event zuordnen
      </Text>
    ),
    children: <AssignEventModal {...props} />,
  });

const AssignEventModal: FC<ChangeEventProps> = ({ bookitupDocument, onChange }) => {
  const { id: documentId, eventId: initialEventId, customerId } = bookitupDocument;
  const [loading, setLoading] = useState(false);
  const [selectedEvent, selectEvent] = useState<string | null>(null); // Let's use string for better usage with Select data
  const [bookingEvents, setBookingEvents] = useState<BookingEvent[]>([]);

  const { length } = bookingEvents;
  const emptyEvents = !loading && length === 0;
  const btnDisabled = initialEventId === selectedEvent || loading;

  useEffect(() => {
    setLoading(true);
    EventS.fetchEventsByCustomerParticipations(customerId)
      .then(setBookingEvents)
      .finally(() => setLoading(false));
  }, [customerId]);

  const onConfirm = async () => {
    if (selectedEvent) {
      setLoading(true);
      const patched = await DocumentS.patchDocument(documentId, { eventId: Number(selectedEvent) });
      setLoading(false);
      if (patched) {
        ToastS.success('foo', 'Event zugewiesen');
        onChange();
        closeAllModals();
      }
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Select
        placeholder="Event wählen"
        icon={<IconCalendarEvent />}
        data={DataS.getEventOptions(bookingEvents)}
        value={selectedEvent}
        onChange={selectEvent}
        nothingFound={
          emptyEvents && (
            <Text>Es wurde kein Event zu diesem Kontakt gefunden. Bitte füge den Kontakt erst einem Event hinzu.</Text>
          )
        }
      />
      <Center mt="xl">
        <Button onClick={onConfirm} disabled={btnDisabled} sx={{ width: 250 }}>
          Speichern
        </Button>
      </Center>
    </>
  );
};
