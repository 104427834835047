/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import SubscriptionS from '../restapi/subscriptionService';
import { AuthContext } from '../Context/AuthContext';

// https://developers.hubspot.com/docs/reference/api/conversations/chat-widget-sdk
declare global {
  interface Window {
    hsConversationsSettings: any;
    hsConversationsOnReady: any;
    HubSpotConversations: any;
  }
}

const useHubspot = () => {
  const { jwtClaims } = useContext(AuthContext);
  const { supportLogin, email } = jwtClaims;
  const [loaded, setLoaded] = useState(false);

  const idenfity = (token: string | null) => {
    console.info(`Identiying: ${email}, ${token}`);
    if (token) {
      window.hsConversationsSettings = {
        identificationEmail: email,
        identificationToken: token,
      };
    }
    const status = window.HubSpotConversations.widget.status();
    if (status.loaded) {
      window.HubSpotConversations.widget.refresh();
    } else {
      window.HubSpotConversations.widget.load();
    }
  };

  useEffect(() => {
    if (!supportLogin && loaded) {
      if (email) {
        SubscriptionS.getHubSpotIdTokenAPI().then(idenfity);
      } else {
        idenfity(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportLogin, email, loaded]);

  // Load JS file
  useEffect(() => {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
    window.hsConversationsOnReady = [
      () => {
        setLoaded(true);
        console.info('HubSpot Conversations loaded');
      },
    ];
    const script = document.createElement('script');
    script.id = 'hs-script-loader';
    script.src = 'https://js-eu1.hs-scripts.com/25589471.js';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
    };
  }, []);
};

export default useHubspot;
