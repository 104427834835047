import { closeAllModals } from '@mantine/modals';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { fetchEventById } from '../../../../Service/restapi/eventService';
import { ContactS } from '../../../../Service/ContactS';

export default function ({ index, eventId, value }) {
  const [event, setEvent] = useState();
  const [customer, setCustomer] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (eventId) {
      fetchEventById(eventId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setEvent);
        }
      });
    }
  }, [eventId]);

  useEffect(() => {
    if (event && event.eventDate === null) {
      ContactS.fetchById(event.customerId).then(setCustomer);
    }
  }, [event]);

  if (!event) {
    return null;
  }

  const onClick = () => {
    closeAllModals();
    navigate(`/events/${event.id}/details`);
  };

  const getFinalValue = () => {
    if (typeof value === 'boolean') {
      return value ? 'Ja' : 'Nein';
    }
    return value;
  };

  const styles = {
    container: {
      display: 'flex',
      padding: 10,
      backgroundColor: index % 2 ? '#fbfbfb' : 'unset',
    },
  };

  return (
    <div style={styles.container} className="clickable" onClick={onClick}>
      {event && event.eventDate && event.eventDate.date && (
        <div>
          Event am <Moment format="DD.MM.YYYY">{event.eventDate.date}</Moment> - {getFinalValue()}
        </div>
      )}
      {customer && <div>{`Event für ${customer.personDescription} - ${getFinalValue()}`}</div>}
      {!event && !customer && 'Laden ...'}
    </div>
  );
}
