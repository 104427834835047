import { useContext, useEffect, useState } from 'react';
import { Space, Switch } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EventContext } from '../../Service/Context/EventContext';
import DocumentHeadData from '../../Molecules/DocumentForm';
import DocumentSkeleton from '../../Organismns/Document/DocumentSkeleton';
import { EventLocationContext } from '../../Service/Context/EventLocationContext';
import { fetchFlattenedCustomAttributeValues } from '../../Service/restapi/customAttributeService';
import { DocumentContext } from '../../Service/Context/DocumentContext';
import { useEventCustomer } from '../../Service/Context/CustomerContext';
import PanePaper from '../../Molecules/PanePaper';
import DocumentHeadTemplate from '../DocumentHeadTemplate';
import DocumentActions from '../../Molecules/Document/DocumentActions';
import { useXRechnungValidator } from '../../Service/hooks/useXRechnungValidator';
import { isDefined } from '../../Service/ValidationS';
import { DocumentS } from '../Documents/DocumentS';

const EventDocumentForm = () => {
  const { i18n } = useLingui();
  const navigate = useNavigate();
  const { document, handleChange, addPosition, savePatch } = useContext(DocumentContext);
  const { location, distance } = useContext(EventLocationContext);
  const { event } = useContext(EventContext);
  const [customAttributeValues, setCustomAttributeValues] = useState(undefined);
  const { customer, patchCustomer } = useEventCustomer(document.customerId);
  const { id, draft } = document;
  const { id: eventId } = event;
  const [searchParams] = useSearchParams();
  const { valid } = useXRechnungValidator(document, customer);
  const emptyPositions = document && (!document.positions || document.positions.length === 0);

  useEffect(() => {
    if (!draft && !searchParams.get('unlock')) {
      // Redirect to document preview if no draft
      navigate('preview');
    }
  }, [draft, navigate, searchParams]);

  useEffect(() => {
    if (!document.customerId && event) {
      handleChange({ customerId: event.customerId });
    }
  }, []);

  useEffect(() => {
    fetchFlattenedCustomAttributeValues('event', event.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((flattenedValues) => {
            setCustomAttributeValues(flattenedValues);
          });
        } else {
          console.error(`could not load flattened custom attributes of event ${event.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load flattened custom attributes of event ${event.id}`);
      });
  }, [event.id]);

  const documentType = i18n._(`document.${document.documentType.toLowerCase()}`);

  return (
    <PanePaper withShadow>
      <DocumentHeadTemplate
        entityId={id}
        entityType="documents"
        title={`${documentType} erstellen`}
        previewUrl={eventId ? `/events/${eventId}/documents/${id}/preview` : `/documents/${id}/preview`}
        documentActions={<DocumentActions />}
        disablePreview={emptyPositions || !valid}
        // eInvoiceSwitch={
        //   DocumentS.isInvoice(document.documentType) && (
        //     <Switch
        //       label="E-Rechnung"
        //       checked={isDefined(customer.buyerReference)}
        //       onChange={(e) => {
        //         patchCustomer({ buyerReference: e.target.checked ? '0' : null });
        //         handleChange({ xrechnungRequired: e.target.checked });
        //       }}
        //     />
        //   )
        // }
      />
      <Space h="xl" />
      <DocumentHeadData customer={customer} />
      <Space h="xl" />
      <DocumentSkeleton
        _document={document}
        customer={customer}
        handleChange={handleChange}
        addPosition={addPosition}
        event={event}
        location={location}
        distance={distance}
        customAttributeValues={customAttributeValues}
        savePatch={savePatch}
      />
    </PanePaper>
  );
};

export default EventDocumentForm;
