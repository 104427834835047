import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';
import { BookingEventDateItem, EventsFilter, EventsView } from '../../Types/Event';
import { EventS } from '../EventS';
import { MixpanelS } from '../MixpanelS';
import { ConstantS } from '../ConstantS';
import { PageProps } from '../../Types/PageableResponse';
import { BrowserS } from '../BrowserS';
import { useJwtClaims } from './AuthContext';

interface EventsContextInterface {
  bookingEvents: BookingEventDateItem[];
  loading: boolean;
  filter: EventsFilter;
  totalCount: number | undefined;
  networkError: boolean;
  updateFilter: (patch: Partial<EventsFilter>) => unknown;
  view: EventsView;
  setView: (newView: EventsView) => unknown;
  pageProps: PageProps;
}

export const EventsContext = createContext<EventsContextInterface>({} as EventsContextInterface);

export const EventsContextProvider: FC<{ defaultFilter?: EventsFilter }> = ({
  defaultFilter = BrowserS.loadEventsFilter(),
}) => {
  const { profileId, licence } = useJwtClaims();
  const [loading, setLoading] = useState(false);
  const [bookingEventsDates, setBookingEventsDates] = useState<BookingEventDateItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [networkError, setNetworkError] = useState(false);
  const [view, setView] = useLocalStorage<EventsView>({
    key: 'events-view',
    defaultValue: 'list',
  });

  const [pageProps, setPageProps] = useState<PageProps>({
    totalElements: 0,
    totalPages: 0,
  });

  const [showEventsWithoutRecommendations, setShowAllEvents] = useLocalStorage({
    key: 'showEventsWithoutRecommendations',
    defaultValue: false,
  });

  const [filter, setFilter] = useState<EventsFilter>(
    defaultFilter
      ? { ...defaultFilter, profiles: [profileId] }
      : {
          page: 0,
          interval: 'FUTURE',
          sorting: 'chronological',
          answersOfInterest: ['AVAILABLE'],
          profiles: [profileId],
          showAllEvents: false,
          agencyModeActive: false,
          filterCustomerContactShared: false,
          statesOfInterest: ['OFFERED', 'OPEN', 'BOOKED'],
          customStatesOfInterest: [],
          kindsOfInterest: [],
          customersOfInterest: [],
          recommendationBookingStates: ['OFFERED', 'OPEN', 'BOOKED'],
          showAppointments: true,
        },
  );

  useEffect(() => {
    if (!defaultFilter) {
      EventS.getDefaultFilterStates(licence).then(updateFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);

  const { agencyModeActive, showAllEvents, sorting } = filter;

  useEffect(() => {
    BrowserS.saveEventsFilter(filter);
    if (filter.sorting === 'dateless' || view === 'calendar') {
      return;
    }
    setLoading(true);
    const abortController = new AbortController();
    let fetchEvents: () => Promise<BookingEventDateItem[]>;

    if (agencyModeActive) {
      fetchEvents = () => EventS.fetchRecommendationsV3(filter, setTotalCount, abortController.signal);
    } else if (sorting === 'chronological') {
      fetchEvents = () => EventS.fetchEventsV2(filter, setTotalCount, abortController.signal);
    } else {
      fetchEvents = async () => {
        const pageRes = await EventS.fetchEventsSortedPageV2(filter, abortController.signal);
        if (pageRes) {
          const { content, totalElements, totalPages } = pageRes;
          setPageProps({ totalElements, totalPages });
          return content;
        }
        return [];
      };
    }
    fetchEvents()
      .then(setBookingEventsDates)
      .catch(() => {
        if (!abortController.signal.aborted) {
          setNetworkError(true);
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });

    // eslint-disable-next-line consistent-return
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  // Update localstorage setting after option is changed
  useEffect(() => {
    if (showAllEvents !== showEventsWithoutRecommendations) {
      setShowAllEvents(showAllEvents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllEvents]);

  useEffect(() => {
    if (agencyModeActive) {
      MixpanelS.track(ConstantS.TrackingEvents.AgencyModeActivated);
    }
  }, [agencyModeActive]);

  useEffect(() => {
    if (sorting === 'createdAt') {
      MixpanelS.track(ConstantS.TrackingEvents.EventsFilteredByCreationDate);
    }
  }, [sorting]);

  useEffect(() => {
    // Remove custom date interval after view is switched from 'timeline' to 'list'
    if (view === 'list' && (filter.from || filter.to)) {
      updateFilter({ from: undefined, to: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const updateFilter = (patch: Partial<EventsFilter>) => {
    if (filter.sorting === 'createdAt' && !patch.page) {
      // eslint-disable-next-line no-param-reassign
      patch.page = 0;
    }
    setFilter((prev) => ({ ...prev, ...patch }));
  };

  const value = useMemo(
    () => ({
      bookingEvents: bookingEventsDates,
      loading,
      filter,
      totalCount,
      networkError,
      view,
      pageProps,
      setView,
      updateFilter,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bookingEventsDates, loading, filter, totalCount, networkError, view, pageProps],
  );

  return (
    <EventsContext.Provider value={value}>
      <Outlet />
    </EventsContext.Provider>
  );
};
