import { useContext, useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import { Group, NumberInput, Select, Stack, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { EditableDocumentPositionProperty } from '../../../Atoms/EditableProperties/EditableDocumentPositionProperty';
import { EditableAutocomplete } from '../../../Atoms/inputs/EditableAutocomplete';
import { HtmlPlaceholderFormatter } from '../../../Atoms/Formatter/HtmlPlaceholderFormatter';
import { HtmlFormatter } from '../../../Atoms/Formatter/HtmlFormatter';
import { EditableDivInput } from '../../../Atoms/inputs/EditableDivInput';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import { translate } from '../../../Service/PlaceholderTranslationService';
import { EditableFavoritePositionProperty } from '../../../Atoms/EditableProperties/EditableFavoritePositionProperty';
import { AutocompleteListTopComponent } from '../../../Atoms/AutocompleteListTopComponent';
import { GrossNetEditableText } from '../../../Atoms/inputs/GrossNetEditableText';
import { EditableAutocompleteWithPlaceholderCollection } from '../../../Atoms/inputs/EditableAutocompleteWithPlaceholderCollection';
import { patchFavoritePosition, patchPosition } from '../../../Service/restapi/positionService';
import { FormatS } from '../../../Service/FormatS';
import { DataS } from '../../../Service/DataS';
import { NetGrossFormatter } from '../../../Atoms/Formatter/NetGrossFormatter';
import PositionSearchListItem from './PositionSearchListItem';
import PositionOptionalCheckbox from './PositionOptionalCheckbox';
import BookitupSegmentedControl from '../../../Atoms/BookitupSegmentedControl';
import { TestS } from '../../../TestS';
import ResourcePositionAssignment from '../../../Organismns/Settings/Documents/Positions/ResourcePositionAssignment';
import { useOwnContact } from '../../../Service/Context/ContactContext';

// eslint-disable-next-line import/prefer-default-export
export const Position = ({
  index,
  position: initialPosition,
  changePosition,
  _document,
  isFavoritePosition,
  favorites,
  customer,
  event,
  eventLocation,
  distance,
  customAttributeValues,
  settings,
  onPlaceholderClicked,
  fetchFavorites,
}) => {
  const { i18n } = useLingui();
  const [position, setPosition] = useState(initialPosition);
  const { profile } = useContext(ProfileContext);
  const isPartialInvoice = _document && _document.documentType === 'PARTIAL_INVOICE';
  const taxType = _document ? _document.taxType : 'gross';
  const { discount, id: positionId, price, tax, netPrice } = position;
  const { value: discountValue, type: discountType } = discount;

  const { contact } = useOwnContact();
  const invalidNegativePrice = contact && contact.contactType === 'COMPANY' && price < 0;

  function change(index, position) {
    setPosition(position);
    if (typeof changePosition === 'function') {
      changePosition(index, position);
    }
  }

  useEffect(() => {
    setPosition(initialPosition);
  }, [initialPosition]);

  let EditablePositionProperty = EditableDocumentPositionProperty;
  if (isFavoritePosition) {
    EditablePositionProperty = EditableFavoritePositionProperty;
  }

  function preChange(patch) {
    console.log('preChange', patch);
    if (patch.amount) {
      patch.amount = patch.amount.replace(/(<([^>]+)>)/gi, '');
    }
    if (patch.amount === '{location_distance}') {
      if (!settings) {
        if (distance && distance.distanceInMeters) {
          // patch.amount = `${Math.round(distance.distanceInMeters / 1000)}`;
          patch.unit = 'km';
        } else {
          patch.amount = null;
        }
      }
    } else if (patch.amount === '{location_double_distance}') {
      if (!settings) {
        if (distance && distance.doubleDistanceInKiloMeters) {
          patch.amount = `${distance.doubleDistanceInKiloMeters}`;
          patch.unit = 'km';
        } else {
          patch.amount = null;
        }
      }
    } else if (patch.amount === '{event_hours}') {
      if (!settings) {
        if (event && event.dates.length > 0 && event.dates[0].hours) {
          // patch.amount = `${event.dates[0].hours}`;
          patch.unit = 'Std.';
        } else {
          patch.amount = null;
        }
      }
    }
    for (const obj in patch) {
      patch[obj] = translate.toEnglish(patch[obj], i18n);
    }
    return patch;
  }

  const patchPositionField = (_index, patch) => {
    if (isFavoritePosition) {
      patchFavoritePosition(positionId, patch).then((resp) => {
        if (resp.ok) {
          resp.json().then((patchedPosition) => {
            if (patchedPosition) {
              change(_index, patchedPosition);
            }
          });
        }
      });
    } else {
      patchPosition(positionId, patch).then((resp) => {
        if (resp.ok) {
          resp.json().then((patchedPosition) => {
            if (patchedPosition) {
              change(_index, patchedPosition);
            }
          });
        }
      });
    }
  };

  return (
    <Stack spacing="xs" sx={{ flexGrow: '1 !important' }}>
      <Group spacing="xs">
        <EditablePositionProperty
          index={index}
          preChange={preChange}
          data-testid="0-label"
          name="label"
          patchField="label"
          changeHandler={(value) => {
            change(index, value);
          }}
          entity={position}
          suggestions={favorites}
          editFormatter={EditableAutocompleteWithPlaceholderCollection}
          labelFormatter={HtmlPlaceholderFormatter}
          listValueComponent={PositionSearchListItem}
          listTopComponent={<AutocompleteListTopComponent text="Position aus Favoriten auswählen" />}
          customer={customer}
          _document={_document}
          event={event}
          eventLocation={eventLocation}
          customAttributeValues={customAttributeValues}
          settings={settings}
          style={{ overflow: 'hidden' }}
          useGrow
        />
        <EditablePositionProperty
          index={index}
          patchField="amount"
          preChange={preChange}
          changeHandler={(value) => {
            change(index, value);
          }}
          entity={position}
          settings={settings}
          suggestions={[
            { amount: '{event_hours}' },
            { amount: '{location_distance}' },
            { amount: '{location_double_distance}' },
          ]}
          event={event}
          _document={_document}
          eventLocation={eventLocation}
          editFormatter={EditableAutocomplete}
          labelFormatter={HtmlPlaceholderFormatter}
          disabled={isPartialInvoice}
          style={{ width: 50, overflow: 'hidden', maxHeight: 34 }}
        />

        <EditablePositionProperty
          index={index}
          patchField="unit"
          changeHandler={(value) => {
            change(index, value);
          }}
          entity={position}
          suggestions={[
            { unit: 'Stk.' },
            { unit: 'Std.' },
            { unit: 'km' },
            { unit: 'Tag(e)' },
            { unit: 'kg' },
            { unit: 'm' },
            { unit: 'm²' },
            { unit: 'Personen' },
            { unit: 'l' },
          ]}
          editFormatter={EditableAutocomplete}
          labelFormatter={HtmlFormatter}
          disabled={isPartialInvoice}
          style={{ width: 60, overflow: 'hidden' }}
        />

        <EditablePositionProperty
          index={index}
          patchField="priceUnit"
          changeHandler={(value) => {
            change(index, value);
          }}
          entity={position}
          editFormatter={GrossNetEditableText}
          labelFormatter={NetGrossFormatter}
          taxType={taxType}
          tax={position.tax}
          style={{ width: 70, backgroundColor: invalidNegativePrice ? '#FF8D8C' : 'inherit' }}
          withoutCurrency
        />
        {!profile.smallScaleBusiness && (
          <Select
            value={tax}
            data={DataS.taxRateOptions}
            sx={{ width: 60 }}
            onChange={(val) => patchPositionField(index, { tax: val })}
            styles={{ rightSection: { pointerEvents: 'none' } }}
            rightSection={<IconChevronDown size={14} />}
            rightSectionWidth={25}
          />
        )}
        {!isPartialInvoice && (
          <>
            <NumberInput
              hideControls
              decimalSeparator=","
              precision={2}
              value={discountValue}
              sx={{ width: 70 }}
              onBlur={(e) => {
                const intVal = parseFloat(e.target.value.replace(',', '.'));
                const finalVal = isNaN(intVal) || intVal < 0 ? 0 : intVal;
                patchPositionField(index, { discountValue: finalVal });
              }}
              min={0}
              data-testid={TestS.Documents.Edit.Position.DISCOUNT_VALUE}
            />
            <BookitupSegmentedControl
              value={discountType}
              data={DataS.discountUnitOptions}
              onChange={(val) => val && patchPositionField(index, { discountType: val })}
              sx={{ marginLeft: -10 }}
              testId={TestS.Documents.Edit.Position.DISCOUNT_TYPE}
            />
          </>
        )}
        <Text align="center" sx={{ width: 100 }}>
          {FormatS.formatCurrencyWithUnitSign(taxType === 'net' ? netPrice : price)}
        </Text>
      </Group>

      <EditablePositionProperty
        index={index}
        onPlaceholderClicked={onPlaceholderClicked}
        preChange={preChange}
        name="subtext"
        patchField="subtext"
        changeHandler={(value) => {
          change(index, value);
        }}
        entity={position}
        editFormatter={EditableDivInput}
        labelFormatter={HtmlPlaceholderFormatter}
        customer={customer}
        _document={_document}
        event={event}
        eventLocation={eventLocation}
        customAttributeValues={customAttributeValues}
        settings={settings}
      />
      {_document && (
        <>
          <Group position="apart" sx={{ paddingTop: 10, paddingLeft: 5 }}>
            {_document.documentType === 'OFFER' && (event || settings) && (
              <ResourcePositionAssignment
                event={event}
                position={position}
                amount={position.amount}
                preview={false}
                favoritePosition={false}
              />
            )}
            <PositionOptionalCheckbox
              index={index}
              position={position}
              documentType={_document.documentType}
              changePosition={changePosition}
            />
          </Group>
        </>
      )}
    </Stack>
  );
};
