import { endOfMonth, lastDayOfYear, subDays } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { EventInput } from '@fullcalendar/core';
import { i18n, I18n } from '@lingui/core';
import BookingState, { getBookingStateColor } from '../Types/Enums/BookingState';
import BookingEvent, {
  BookingEventDateItem,
  CreateEventRequest,
  CreateEventRequestV2,
  DatelessEventsFilter,
  EventsFilter,
} from '../Types/Event';
import { EventDate, EventKind, CustomEventState } from '../Types/EventT';
import { EventParticipationDto } from '../Types/MessageT';
import { getEventDateInterval } from '../Utils/dateUtils';
import { ContactDescription } from '../Types/AuthT';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';
import { getBookingStateColour } from './restapi/eventService';
import { Licence } from './Context/AuthContext';
import { LicenceS } from './LicenceS';
import { MixpanelS } from './MixpanelS';
import { ConstantS } from './ConstantS';
import { PageableResponse } from '../Types/PageableResponse';

const Dates = {
  DATE: 'date',
  TIME_FROM: 'timeFrom',
  TIME_TO: 'timeTo',
};

const translateBookingState = (bookingState: BookingState): string => {
  switch (bookingState) {
    case 'OPEN':
      return 'Offen';
    case 'OFFERED':
      return 'Option';
    case 'BOOKED':
      return 'Bestätigt';
    case 'CANCELED':
      return 'Annulliert';
    case 'UNAVAILABLE':
      return 'Nicht verfügbar';
    default:
      return '';
  }
};

const getKindDescription = (kind: string | undefined): string => {
  if (kind && kind !== '') {
    return `${kind} `;
  }
  return 'Event   ';
};

const getBookingStateTooltipLabel = (state: BookingState) => {
  switch (state) {
    case BookingState.BOOKED:
      return 'Bestätigt';
    case BookingState.CANCELED:
      return 'Annulliert';
    // case BookingState.COMPLETED:
    //   return 'green';
    // case BookingState.EARMARKED:
    //   return 'green';
    case BookingState.OFFERED:
      return 'Option';
    case BookingState.OPEN:
      return 'Anfrage';
    // case BookingState.PAYMENT:
    //   return 'green';
    // case BookingState.RECOMMENDED:
    //   return 'green';
    case BookingState.UNAVAILABLE:
      return 'Nicht verfügbar';
    default:
      return '';
  }
};

const BASE_URL = process.env.REACT_APP_EVENT_SERVICE_URL;

const createEvent = async (event: CreateEventRequest): Promise<BookingEvent | null> => {
  const resp = await ajaxActions.post(`${BASE_URL}/events`, event);
  if (resp.ok) {
    ToastS.success('event-created', 'Event erfolgreich angelegt.');
    return resp.json();
  }
  ToastS.generalError();
  return null;
};

const createEventV2 = async (event: CreateEventRequestV2): Promise<BookingEvent | null> => {
  const resp = await ajaxActions.post(`${BASE_URL}/events/v2`, event);
  if (resp.ok) {
    ToastS.success('event-created', 'Event erfolgreich angelegt.');
    MixpanelS.track(ConstantS.TrackingEvents.EventCreated, { datesCounts: event.dates.length });
    return resp.json();
  }
  ToastS.generalError();
  return null;
};

const editEvent = (eventId: number, patch: Partial<BookingEvent>): Promise<BookingEvent | null> =>
  ajaxActions.patch(`${BASE_URL}/events/${eventId}`, patch).then((resp) => {
    if (resp.ok) {
      return resp.json();
    }
    ToastS.generalError();
    return null;
  });

const editEventDate = async (eventId: number, patch: Partial<EventDate>): Promise<EventDate | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/events/${eventId}/dates`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteEvent = async (eventIds: string[], i18n: I18n): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/events`, eventIds);
  if (res.ok) {
    ToastS.success('event.delete', i18n._('actions.event.delete.success'));
    MixpanelS.track(ConstantS.TrackingEvents.EventDeleted);
    return true;
  }
  ToastS.error(`event.delete.${res.status}.title`, i18n._(`errors.${res.status}.boxed.title`));
  ToastS.error(`event.delete.${res.status}.description`, i18n._(`errors.${res.status}.boxed.description`));
  return false;
};

const shareEvent = async (
  eventId: number,
  recommendedContactId: number,
  recommendationId: string,
  config: Record<string, unknown>,
): Promise<boolean> => {
  const res = await ajaxActions.put(
    `${BASE_URL}/export/events/${eventId}?recommendedContactId=${recommendedContactId}&recommendationId=${recommendationId}`,
    config,
  );
  if (res.ok) {
    return true;
  }
  if (res.status === 404) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const createEventKind = async (kind: Partial<EventKind>): Promise<EventKind | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/kinds`, kind);
  if (res.ok) {
    const { name } = kind;
    ToastS.success('kind-created', `Art: ${name} erfolgreich erstellt`);
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const fetchEventKinds = async (): Promise<EventKind[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/kinds/all`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const editEventKind = async (id: string, patch: Partial<EventKind>): Promise<EventKind | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/kinds/${id}`, patch);
  if (res.ok) {
    ToastS.success('foo', 'Ereignisart erfolgreich aktualisiert');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteEventKind = async (id: string, replacementId?: string): Promise<boolean> => {
  let targetUrl = `${BASE_URL}/kinds/${id}`;
  if (replacementId) {
    targetUrl += `?replacementId=${replacementId}`;
  }
  const res = await ajaxActions.del(targetUrl);
  if (res.status === 204) {
    ToastS.info('foo', 'Art wurde gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const fetchLeadOrigins = () =>
  ajaxActions.get(`${BASE_URL}/leadOrigins`).then((resp) => {
    if (resp.ok) {
      return resp.json();
    }
    return null;
  });

const fetchEventsV2 = async (
  filter: EventsFilter,
  setTotalCount: (count: number) => unknown,
  abortSignal?: AbortSignal,
): Promise<BookingEventDateItem[]> => {
  const {
    from,
    to,
    statesOfInterest,
    interval,
    profiles,
    customStatesOfInterest,
    kindsOfInterest,
    customersOfInterest,
    showAppointments,
  } = filter;
  let targetUrl = `${BASE_URL}/events/v2/own/filter?interval=${interval}&bookingStates=${statesOfInterest}&profiles=${profiles}&customBookingStates=${customStatesOfInterest}&kindsOfInterest=${kindsOfInterest}&customersOfInterest=${customersOfInterest}&showAppointments=${showAppointments}`;
  if (from && to) {
    targetUrl += `&from=${from}&to=${to}`;
  }
  const res = await ajaxActions.get(targetUrl, { signal: abortSignal });
  if (res.ok) {
    const totalCount = res.headers.get('X-Total-Count');
    if (totalCount) {
      setTotalCount(parseInt(totalCount, 10));
    }
    return res.json();
  }
  return [];
};

const fetchCalendarEvents = async (
  from: Date,
  to: Date,
  filter: EventsFilter,
  abortSignal?: AbortSignal,
): Promise<EventInput[]> => {
  const { statesOfInterest, profiles, customStatesOfInterest, kindsOfInterest, customersOfInterest } = filter;
  const res = await ajaxActions.get(
    `${BASE_URL}/events/calendar/filter?from=${from.toISOString()}&to=${
      to ? to.toISOString() : ''
    }&bookingStates=${statesOfInterest}&kindsOfInterest=${kindsOfInterest}&customersOfInterest=${customersOfInterest}&profiles=${profiles}&customBookingStates=${customStatesOfInterest}`,
    { signal: abortSignal },
  );
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchEventsByCustomerParticipations = async (customerId: number): Promise<BookingEvent[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/events/participations?customerId=${customerId}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return [];
};

const fetchDatelessEvents = async (filter: DatelessEventsFilter): Promise<BookingEvent[]> => {
  const { statesOfInterest, customStatesOfInterest, customersOfInterest } = filter;
  const res = await ajaxActions.get(
    `${BASE_URL}/events/dateless?bookingStates=${statesOfInterest}&customBookingStates=${customStatesOfInterest}&customersOfInterest=${customersOfInterest}`,
  );
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchRecommendationsV3 = async (
  filter: EventsFilter,
  setTotalCount: (count: number) => unknown,
  abortSignal?: AbortSignal,
): Promise<BookingEventDateItem[]> => {
  const {
    interval,
    statesOfInterest,
    customStatesOfInterest,
    kindsOfInterest,
    customersOfInterest,
    recommendationBookingStates,
    answersOfInterest,
    profiles,
    lastContactShare,
    recommendationContact,
    showAllEvents,
    filterCustomerContactShared,
  } = filter;
  const res = await ajaxActions.get(
    `${BASE_URL}/events/v3/recommendations/filter?interval=${interval}&recommendationBookingStates=${recommendationBookingStates}&bookingStates=${statesOfInterest}&customBookingStates=${customStatesOfInterest}&kindsOfInterest=${kindsOfInterest}&customersOfInterest=${customersOfInterest}&profiles=${profiles}&availabilities=${answersOfInterest}&recommendationContacts=${[
      recommendationContact,
    ]}&filterCustomerContactShared=${filterCustomerContactShared}${
      lastContactShare ? `&customerContactSharedAfter=${new Date(1970, 1, 1).toISOString()}` : ''
    }&showAllEvents=${showAllEvents}`,
    { signal: abortSignal },
  );
  if (res.ok) {
    const totalCount = res.headers.get('X-Total-Count');
    if (totalCount) {
      setTotalCount(parseInt(totalCount, 10));
    }
    return res.json();
  }
  return [];
};

const fetchEventsSortedV2 = async (
  filter: EventsFilter,
  abortSignal?: AbortSignal,
): Promise<BookingEventDateItem[]> => {
  const { sorting } = filter;
  const res = await ajaxActions.get(`${BASE_URL}/events/v2/sorted?sorting=${sorting}`, { signal: abortSignal });
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchEventsSortedPageV2 = async (
  filter: EventsFilter,
  abortSignal?: AbortSignal,
): Promise<PageableResponse<BookingEventDateItem> | null> => {
  const { page, sorting, statesOfInterest, customStatesOfInterest, kindsOfInterest, customersOfInterest } = filter;
  const res = await ajaxActions.get(
    `${BASE_URL}/events/v2/sorted/page?sorting=${sorting}&page=${page}&bookingStates=${statesOfInterest}&customBookingStates=${customStatesOfInterest}&kindsOfInterest=${kindsOfInterest}&customersOfInterest=${customersOfInterest}`,
    { signal: abortSignal },
  );
  if (res.ok) {
    return res.json();
  }
  return null;
};

const getDateIntervals = () => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const dateRangePickerMinimum = new Date(2020, 0, 1);
  const dateRangePickerMaximum = new Date(currentYear + 10, 1, 1);

  const startOfYear = new Date(currentYear, 0, 1);
  const endOfYear = lastDayOfYear(today);

  const startOfPreviousYear = new Date(currentYear - 1, 0, 1);
  const endOfPreviousYear = lastDayOfYear(startOfPreviousYear);

  const startOfMonth = new Date(currentYear, currentMonth, 1);
  const monthEnd = endOfMonth(today);

  const startOfNextMonth = new Date(
    currentMonth === 11 ? currentYear + 1 : currentYear,
    currentMonth === 11 ? 0 : currentMonth + 1,
    1,
  );
  const endOfNextMonth = endOfMonth(startOfNextMonth);

  const startOfLastMonth = subDays(startOfMonth, 1);
  startOfLastMonth.setDate(1);
  const endOfTheLastMonth = endOfMonth(startOfLastMonth);

  return {
    dateRangePickerMinimum,
    dateRangePickerMaximum,
    startOfYear,
    startOfPreviousYear,
    startOfLastMonth,
    endOfPreviousYear,
    endOfYear,
    startOfMonth,
    startOfNextMonth,
    today,
    monthEnd,
    endOfNextMonth,
    endOfTheLastMonth,
  };
};

const fetchById = async (eventId: number): Promise<BookingEvent | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/events/${eventId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const getEventParticipations = async (eventId: string): Promise<EventParticipationDto[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/eventParticipations/${eventId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const importEventsCSV = async (csvFile: File, importPastEvents: boolean, importCancelledEvents: boolean) => {
  const res = await ajaxActions.uploadPutFile(
    `${BASE_URL}/import/events?importPastEvents=${importPastEvents}&importCancelledEvents=${importCancelledEvents}`,
    csvFile,
  );
  if (res.ok) {
    ToastS.success('import-event', 'Import finished');
  } else {
    ToastS.generalError();
  }
};

const fetchEventStates = async (): Promise<CustomEventState[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/event-status/all`);
  if (res.ok) {
    const resJSON = await res.json();
    return _.orderBy(resJSON, ['originState']);
  }
  return [];
};

const editStatus = async (id: string, patch: Partial<CustomEventState>): Promise<CustomEventState | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/event-status/${id}`, patch);
  if (res.ok) {
    ToastS.success('foo', 'Der Ereignisstatus wurde erfolgreich aktualisiert');
    return res.json();
  }
  return null;
};

const fetchCustomState = async (id: string): Promise<CustomEventState | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/event-status/${id}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const createStatus = async (state: Partial<CustomEventState>): Promise<CustomEventState | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/event-status`, state);
  if (res.ok) {
    ToastS.success('status-created', `Status: ${state.label} erfolgreich erstellt`);
    return res.json();
  }
  return null;
};

const getStateColor = (event: BookingEvent) => {
  const { eventState, bookingState } = event;
  const { color } = eventState ?? {};
  return color ?? getBookingStateColour(bookingState);
};

const getEventStateValue = (event: BookingEvent, states: CustomEventState[]) => {
  const { bookingState, eventState } = event as BookingEvent;
  if (eventState) {
    return eventState.label;
  }
  const state = states.find((s) => s.originState === bookingState && (!s.id || s.primary));
  return state?.label;
};

const getDefaultFilterStates = async (userLicence: Licence): Promise<Partial<EventsFilter>> => {
  let customStates: CustomEventState[] = [];
  if (LicenceS.hasSuitableLicence('INTERACTIVE', userLicence)) {
    customStates = await fetchEventStates();
    const statesOfInterest: any[] = [];
    const customStatesOfInterest: string[] = [];

    customStates.forEach((customState) => {
      const { id, useWithDefaultFilter, originState } = customState;
      if (useWithDefaultFilter && id) {
        customStatesOfInterest.push(id);
      } else if (!id && ['OPEN', 'OFFERED', 'BOOKED'].includes(originState)) {
        statesOfInterest.push(originState);
      }
    });
    return { customStatesOfInterest, statesOfInterest };
  }
  return { statesOfInterest: ['OPEN', 'OFFERED', 'BOOKED'] };
};

const moveCustomState = async (bookingState: BookingState, from: number, to: number): Promise<boolean> => {
  const res = await ajaxActions.patch(`${BASE_URL}/event-status/${bookingState}/move?from=${from}&to=${to}`, undefined);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const deleteCustomState = async (id: string, replacementId?: string | null): Promise<boolean> => {
  let targetUrl = `${BASE_URL}/event-status/${id}`;
  if (replacementId) {
    targetUrl += `?replacementId=${replacementId}`;
  }
  const res = await ajaxActions.del(targetUrl);
  if (res.status === 204) {
    ToastS.info('foo', 'Zustand wurde gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const sortCustomStates = (customStates: CustomEventState[]): CustomEventState[] => {
  const filterAndSort = (bookingState: BookingState) =>
    _.orderBy(
      customStates.filter((s) => s.originState === bookingState),
      ['idx'],
    );
  return [BookingState.OPEN, BookingState.OFFERED, BookingState.BOOKED, BookingState.UNAVAILABLE, BookingState.CANCELED]
    .map((originState) => filterAndSort(originState))
    .flat();
};

const formatEventDate = (date: Date) => moment(date).format('DD.MM.YYYY');

const formatEventDateWithDay = (date: Date) =>
  `${i18n._(`day.${date.getDay()}`).substring(0, 2)}, ${moment(date).format('DD.MM.YYYY')}`;

const formatEventDateWithTime = (eventDate: EventDate) => {
  const { date, timeFrom, timeTo } = eventDate;
  let val = formatEventDate(new Date(date));
  if (timeFrom && timeTo) {
    val += ` ${timeFrom} - ${timeTo}`;
  }
  return val;
};

const mapEvents = (bookingEventDates: BookingEventDateItem[], contactsDescriptions: ContactDescription): any => {
  const mapEventCalendarView = (bookingEventDate: BookingEventDateItem) => {
    const { id, kind, customerId, timeFrom, timeTo, kindColor, bookingState } = bookingEventDate;
    const personDescription = contactsDescriptions[customerId];
    const [start, end] = getEventDateInterval(bookingEventDate);

    return {
      title: personDescription,
      backgroundColor: getBookingStateColor(bookingState),
      borderColor: 'transparent',
      allDay: !timeFrom && !timeTo,
      start,
      end,
      display: 'block',
      url: `${process.env.PUBLIC_URL}/events/${id}/details`,
      extendedProps: {
        personDescription,
        timeFrom,
        timeTo,
        kind,
        kindColor,
        bookingState,
      },
    };
  };
  return bookingEventDates.map(mapEventCalendarView);
};

const getAsLabel = (bookitupEvent: BookingEvent) => {
  const { dates } = bookitupEvent;
  const primaryDate = dates.find((d) => d.useAsPrimary);
  if (primaryDate) {
    const { weekday } = primaryDate;
    return `${weekday}, ${formatEventDateWithTime(primaryDate)}`;
  }
  return 'Datumsloses event';
};

// eslint-disable-next-line import/prefer-default-export
export const EventS = {
  Dates,
  translateBookingState,
  fetchLeadOrigins,
  fetchEventKinds,
  getKindDescription,
  createEvent,
  createEventV2,
  editEvent,
  deleteEvent,
  editEventDate,
  shareEvent,
  getBookingStateTooltipLabel,
  createEventKind,
  editEventKind,
  deleteEventKind,
  fetchEventsByCustomerParticipations,
  fetchCalendarEvents,
  fetchDatelessEvents,
  fetchEventsV2,
  fetchEventsSortedV2,
  fetchEventsSortedPageV2,
  fetchRecommendationsV3,
  getDateIntervals,
  fetchById,
  getEventParticipations,
  importEventsCSV,
  fetchEventStates,
  editStatus,
  createStatus,
  getStateColor,
  getEventStateValue,
  getDefaultFilterStates,
  moveCustomState,
  deleteCustomState,
  fetchCustomState,
  sortCustomStates,
  formatEventDate,
  formatEventDateWithTime,
  formatEventDateWithDay,
  mapEvents,
  getAsLabel,
};
