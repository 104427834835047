import { useContext, useEffect, useRef, useState } from 'react';
import { Timeline, TimelineWindow } from 'vis-timeline/standalone';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import { Box, Group, LoadingOverlay } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconZoomIn, IconZoomOut } from '@tabler/icons';
import { TimelineS } from '../../Service/TimelineS';
import GradientIcon from '../../Atoms/GradientIcon';
import { DateInterval } from '../../Types/ResourceT';
import { WindowContext } from '../../Service/Context/WindowContext';
import { EventsContext } from '../../Service/Context/EventsContext';

const EventsTimelineView = () => {
  const { xs } = useContext(WindowContext);
  const { loading, bookingEvents, filter, updateFilter } = useContext(EventsContext);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [timeline, setTimeline] = useState<Timeline>();
  const [resolvingItems, setResolvingItems] = useState(false);
  const [timelineInterval, setTimelineInterval] = useState<DateInterval>();

  const moveLeft = () => timeline && move(0.4);
  const moveRight = () => timeline && move(-0.4);
  const zoomIn = () => timeline && timeline.zoomIn(0.4);
  const zoomOut = () => timeline && timeline.zoomOut(0.4);

  const move = (percentage: number) => {
    if (timeline) {
      const range: TimelineWindow = timeline.getWindow();
      const interval = range.end.getTime() - range.start.getTime();
      timeline.setWindow(range.start.valueOf() - interval * percentage, range.end.valueOf() - interval * percentage);
    }
  };

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setTimeline(TimelineS.initialize(containerRef.current, [], setTimelineInterval));
    }
  }, [containerRef]);

  useEffect(() => {
    const abortController = new AbortController();
    if (bookingEvents.length > 0 && timeline) {
      setResolvingItems(true);
      TimelineS.resolveTimelineItems(bookingEvents, abortController.signal)
        .then((items) => TimelineS.update(timeline, items))
        .finally(() => {
          if (!abortController.signal.aborted) {
            setResolvingItems(false);
          }
        });
    }
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingEvents, timeline]);

  useEffect(() => {
    if (timelineInterval) {
      const { start, end } = timelineInterval;
      const { from, to } = filter;
      if (!from || !to) {
        updateFilter({ from: start, to: end });
      } else if (end.getTime() > to.getTime() || start.getTime() < from.getTime()) {
        updateFilter({
          from: start.getTime() < from.getTime() ? start : from,
          to: end.getTime() > to.getTime() ? end : to,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineInterval]);

  return (
    <Box pos="relative" sx={{ minHeight: 600 }}>
      <LoadingOverlay visible={loading || resolvingItems} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Box pos="absolute" sx={{ top: 0, right: 0, zIndex: 1, margin: 10 }}>
        <Group spacing="xs">
          <GradientIcon size={xs ? 'md' : 'xl'} onClick={moveLeft}>
            <IconChevronLeft />
          </GradientIcon>
          <GradientIcon size={xs ? 'md' : 'xl'} onClick={moveRight}>
            <IconChevronRight />
          </GradientIcon>
        </Group>
      </Box>
      <Box pos="absolute" sx={{ top: 0, left: 0, zIndex: 1, margin: 10 }}>
        <Group spacing="xs">
          <GradientIcon size={xs ? 'md' : 'xl'} onClick={zoomOut}>
            <IconZoomOut />
          </GradientIcon>
          <GradientIcon size={xs ? 'md' : 'xl'} onClick={zoomIn}>
            <IconZoomIn />
          </GradientIcon>
        </Group>
      </Box>
      <div ref={containerRef} />
    </Box>
  );
};

export default EventsTimelineView;
