// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Text } from '@mantine/core';
import { IconQuestionCircle } from '@tabler/icons';
import { RoutingS } from '../../../Service/RoutingS';

const ARTICLE_LINK = 'https://bookitup.de/hilfe/wie-kann-ich-das-ressourcen-modul-buchen/';

const AddonManagementPane = () => (
  <Alert
    mt="md"
    icon={<IconQuestionCircle />}
    title="Wie kann ich das Ressourcen-Modul buchen?"
    sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
  >
    <Text>In unserem Hilfeartikel erklären wir, wie Du das Ressourcen Modul dazu buchen kannst.</Text>
    <Button size="xs" mt="md" onClick={() => RoutingS.openInNewTab(ARTICLE_LINK)}>
      Hilfeartikel aufrufen
    </Button>
  </Alert>
);

export default AddonManagementPane;
