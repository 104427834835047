// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, List, Text, ThemeIcon } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { IconCheck, IconFileCertificate, IconX } from '@tabler/icons';
import BookitupDocument from '../../Types/BookitupDocument';
import { Contact } from '../../Service/ContactS';
import { useXRechnungValidator } from '../../Service/hooks/useXRechnungValidator';
import { useOwnProfile } from '../../Service/Context/ProfileContext';

interface Props {
  invoice: BookitupDocument;
  customer?: Contact | null;
}

const EInvoiceViolationAlert: FC<Props> = ({ invoice, customer }) => {
  const navigate = useNavigate();
  const { profile } = useOwnProfile();
  const { smallScaleBusiness } = profile ?? {};
  const {
    valid,
    validAddress,
    validBuyerAddress,
    validTaxFields,
    validBankAccount,
    validCompanyName,
    validDocumentFields,
    buyerEmailProvided,
    negativePositionPrice,
    validTaxType,
  } = useXRechnungValidator(invoice, customer);

  if (valid) {
    return null;
  }

  return (
    <Alert
      icon={<IconFileCertificate />}
      title="Achtung fehlende Angaben!"
      sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
      mb="sm"
    >
      <Text>
        Um eine gültige E-Rechnung zu erstellen, fehlen folgende gesetzliche Pflichtangaben:
      </Text>
      <br />
      <List
        spacing={5}
        size="xs"
        icon={
          <ThemeIcon radius="xl" size="xs">
            <IconCheck size={10} />
          </ThemeIcon>
        }
      >
        <List.Item
          sx={{ fontWeight: !validAddress ? 'bolder' : 'normal' }}
          icon={
            validAddress ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          Deine Anschrift
        </List.Item>
        <List.Item
          sx={{ fontWeight: !validBankAccount ? 'bolder' : 'normal' }}
          icon={
            validBankAccount ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          {' '}
          Deine IBAN und BIC{' '}
        </List.Item>
        <List.Item
          sx={{ fontWeight: !validTaxFields ? 'bolder' : 'normal' }}
          icon={
            validTaxFields ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          {`Deine Steuernummer ${smallScaleBusiness ? '' : 'und Umsatzsteuer-Identifikationsnummer'}`}
        </List.Item>
        <List.Item
          sx={{ fontWeight: !validCompanyName ? 'bolder' : 'normal' }}
          icon={
            validCompanyName ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          {' '}
          Firmenname des Kunden{' '}
        </List.Item>
        <List.Item
          sx={{ fontWeight: !validBuyerAddress ? 'bolder' : 'normal' }}
          icon={
            validBuyerAddress ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          PLZ/Ort des Kunden
        </List.Item>
        <List.Item
          sx={{ fontWeight: !buyerEmailProvided ? 'bolder' : 'normal' }}
          icon={
            buyerEmailProvided ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          Die E-Mail des Kunden muss angegeben werden
        </List.Item>
        <List.Item
          sx={{ fontWeight: !validDocumentFields ? 'bolder' : 'normal' }}
          icon={
            validDocumentFields ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          {' '}
          Rechnungsdatum, Zahlungsziel und Lieferdatum{' '}
        </List.Item>
        {negativePositionPrice && (
          <List.Item
            sx={{ fontWeight: 'bolder' }}
            icon={
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            }
          >
            Position mit negativer Summe
          </List.Item>
        )}
        {!validTaxType && (
          <List.Item
            sx={{ fontWeight: 'bolder' }}
            icon={
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            }
          >
            Rechnung muss eine Netto-Rechnung sein.
          </List.Item>
        )}
      </List>
      <Button size="xs" mt="xl" onClick={() => navigate('/settings/userdata')}>
        Einstellungen vervollständigen
      </Button>
    </Alert>
  );
};

export default EInvoiceViolationAlert;
