import { useContext, useEffect, useState } from 'react';
import { Container, Stack } from '@mantine/core';
import { EventsContext } from '../../Service/Context/EventsContext';
import BookingEvent from '../../Types/Event';
import { EventS } from '../../Service/EventS';
import DatelessEventItem from '../../Molecules/Eventlist/DatelessEventItem';
import EventsNotFound from '../../Organismns/Eventlist/EventsNotFound';
import { EventItemSkeleton } from '../../Atoms/SkeletonItems';

const DatelessEventsList = () => {
  const { filter, totalCount } = useContext(EventsContext);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<BookingEvent[]>([]);
  const { statesOfInterest, customStatesOfInterest, customersOfInterest } = filter;

  useEffect(() => {
    setLoading(true);
    EventS.fetchDatelessEvents({ statesOfInterest, customStatesOfInterest, customersOfInterest })
      .then(setEvents)
      .finally(() => setLoading(false));
  }, [statesOfInterest, customStatesOfInterest, customersOfInterest]);

  return (
    <Container px={0} size={800}>
      {loading && (
        <Stack mt="md">
          {Array.from(Array(4)).map(() => (
            <EventItemSkeleton key={Math.random()} />
          ))}
        </Stack>
      )}
      <Stack>
        {events.map((e) => (
          <DatelessEventItem key={e.id} bookingEvent={e} />
        ))}
      </Stack>
      {!loading && events.length === 0 && <EventsNotFound totalCount={totalCount ?? 1} />}
    </Container>
  );
};

export default DatelessEventsList;
