/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {
  Alert,
  Autocomplete,
  Badge,
  Box,
  Button,
  Center,
  Group,
  LoadingOverlay,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { FC, useState } from 'react';
import { useForm } from '@mantine/form';
import { i18n } from '@lingui/core';
import { closeAllModals, openModal } from '@mantine/modals';
import {
  IconAlertCircle,
  IconAt,
  IconBuildingCommunity,
  IconChevronDown,
  IconHome,
  IconPhone,
  IconPhonePlus,
  IconWorldWww,
  IconZip,
} from '@tabler/icons';
import { DatePicker } from '@mantine/dates';
import BookitupSegmentedControl from '../../Atoms/BookitupSegmentedControl';
import { DataS } from '../../Service/DataS';
import { isEmpty } from '../../Utils/utils';
import GoogleAutocomplete from '../../Atoms/Autocomplete/GoogleAutocomplete';
import ContactGroupSelection from '../../Molecules/Contact/ContactGroupSelection';
import { isDefined, ValidationS } from '../../Service/ValidationS';
import { ContactS, Contact } from '../../Service/ContactS';
import ContactRelations from '../../Molecules/Contact/ContactRelations';
import { ToastS } from '../../Service/ToastS';
import BookitupBadge from '../../Atoms/BookitupBadge';
import { openSelectCreateContactModal } from './SelectCreateContactModal';

export const openCreateContactModal = (onCreate: (contact: Contact) => void) =>
  openModal({
    title: (
      <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
        {i18n._('actions.contact.add')}
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <ContactModal contact={ContactS.ContactSkeleton as Contact} onFinish={onCreate} />,
  });

export const openEditContactModal = (
  contact: Contact,
  refresh?: () => void,
  onFinish?: (contact: Contact) => void,
  onClose?: () => unknown,
) =>
  openModal({
    title: (
      <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
        {i18n._('actions.contact.edit')}
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <ContactModal contact={contact} refresh={refresh} onFinish={onFinish} />,
    onClose,
  });

interface Props {
  contact: Contact;
  onFinish?: (contact: Contact) => void;
  refresh?: () => void;
  closeAll?: boolean;
  onClose?: () => void;
}

const ContactModal: FC<Props> = ({ contact, onFinish, onClose, refresh }) => {
  const partnerValues = ContactS.getPartnerFormValues(contact.partner);
  const { birthday } = contact;
  const contactBirthday = birthday ? new Date(birthday) : undefined;
  const form = useForm({
    initialValues: {
      ...contact,
      minimalInfoMissing: '',
      partnerMinimalInfoMissing: '',
      salutation: contact.salutation ?? '',
      birthday: contactBirthday,
      ...partnerValues,
    },
    validate: {
      firstName: (value) => checkAwsSpecialChars(value),
      lastName: (value) => checkAwsSpecialChars(value),
      partnerFirstName: (value) => checkAwsSpecialChars(value),
      partnerLastName: (value) => checkAwsSpecialChars(value),
      companyName: (value) => checkAwsSpecialChars(value),
      minimalInfoMissing: (value, values) => {
        const { companyName, firstName, lastName, email } = values;
        return isEmpty(companyName) && isEmpty(firstName) && isEmpty(lastName) && isEmpty(email);
      },
      partnerMinimalInfoMissing: (value, values) => {
        if (!addingPartner && !partner) {
          return null;
        }
        const { partnerFirstName, partnerLastName, partnerEmail } = values;
        return isEmpty(partnerFirstName) && isEmpty(partnerLastName) && isEmpty(partnerEmail);
      },
    },
  });

  const [loading, setLoading] = useState(false);
  const { contactType, buyerReference } = form.values;
  const { minimalInfoMissing, partnerMinimalInfoMissing } = form.errors;
  const { id: contactId, partner: initialPartner } = contact;

  const [addingPartner, setAddingPartner] = useState(false);
  const [partner, setPartner] = useState(initialPartner);

  const showPartnerFields = addingPartner || partner;

  const checkAwsSpecialChars = (value: string) =>
    ValidationS.validAwsObjectKeyName(value) ? null : i18n._('error.input-field.invalid-aws-char');

  const close = onClose ?? closeAllModals;

  const onSubmit = async () => {
    setLoading(true);
    const { values } = form;
    let contactPartner;
    if (showPartnerFields) {
      const partnerFields = ContactS.extractPartnerFormValues(values, partner);
      partnerFields.applicationUserId = contact.applicationUserId;
      partnerFields.partnerId = contact.id;
      contactPartner = await ContactS.saveOrUpdate(partnerFields);
    }
    const { minimalInfoMissing, partner: tmp, ...rest } = values;
    delete rest.partnerId;
    if (addingPartner) {
      rest.partnerId = contactPartner.id;
    }
    if (rest.email && rest.email.trim() === '') {
      rest.email = null;
    }
    const updatedContact = await ContactS.saveOrUpdate(rest);

    setLoading(false);
    if (updatedContact) {
      if (onFinish) {
        onFinish(updatedContact);
      }
      if (refresh) {
        refresh();
      }
      close();
    }
  };

  const onPartnerContactSelect = (selectedContactId: number) => {
    setLoading(true);
    ContactS.edit(contactId, { partnerId: selectedContactId })
      .then((_contact) => {
        if (_contact && refresh) {
          refresh();
          closeAllModals();
          ToastS.success('partner-added', 'Partnerkontakt hinzugefügt');
          setTimeout(() => openEditContactModal(_contact, refresh), 500);
        }
      })
      .finally(() => setLoading(false));
  };

  const removePartner = () => {
    if (partner) {
      setLoading(true);
      ContactS.edit(contactId, { partnerId: null })
        .then((_contact) => {
          if (_contact) {
            setPartner(undefined);
            setAddingPartner(false);
            removePartnerValues();
          }
          if (refresh) {
            refresh();
          }
        })
        .finally(() => setLoading(false));
    } else {
      setAddingPartner(false);
      removePartnerValues();
    }
  };

  const removePartnerValues = () => {
    form.setFieldValue('partnerSalutation', '');
    form.setFieldValue('partnerFirstName', '');
    form.setFieldValue('partnerLastName', '');
    form.setFieldValue('partnerPhoneNumber', '');
    form.setFieldValue('partnerPhoneNumberAlt', '');
    form.setFieldValue('partnerEmail', '');
  };

  const CustomerNamePart = (
    <>
      <Group grow spacing="xs" sx={{ alignItems: 'flex-start' }}>
        <Autocomplete label="Anrede" mt="sm" data={['Herr', 'Frau']} {...form.getInputProps('salutation')} />
        <TextInput mt="sm" label="Vorname" {...form.getInputProps('firstName')} />
        <TextInput mt="sm" label="Nachname" {...form.getInputProps('lastName')} />
      </Group>
      <DatePicker
        allowFreeInput
        initialLevel="year"
        maxDate={new Date()}
        mt="sm"
        label="Geburtstag"
        {...form.getInputProps('birthday')}
      />
    </>
  );
  const validRouteId = buyerReference && ValidationS.validInvoiceRouteId(buyerReference);
  const presentBuyerReference = contactType === 'COMPANY' && isDefined(buyerReference);

  const BuyerReferenceInputLabel = (
    <span>
      <Text
        span
        size={validRouteId ? 'xs' : 'sm'}
        weight={validRouteId ? 'normal' : 'bolder'}
        color={validRouteId ? 'initial' : 'blue'}
      >
        Kundenreferenz
      </Text>
      {'  '}/{' '}
      <Text
        span
        size={!validRouteId ? 'xs' : 'sm'}
        weight={!validRouteId ? 'normal' : 'bolder'}
        color={!validRouteId ? 'initial' : 'blue'}
      >
        Leitweg-ID
      </Text>
    </span>
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Center>
        <BookitupSegmentedControl
          data={DataS.contactTypeOptions}
          {...form.getInputProps('contactType')}
          sx={{ minWidth: 200 }}
        />
      </Center>
      <form onSubmit={form.onSubmit(onSubmit)}>
        {minimalInfoMissing && (
          <Alert
            mt="sm"
            mb="sm"
            icon={<IconAlertCircle size="1rem" />}
            title="Fehlende Daten"
            color="red"
            variant="filled"
          >
            Bitte gib einen Namen, eine Firma oder E-Mail-Adresse an.
          </Alert>
        )}
        {partnerMinimalInfoMissing && (
          <Alert
            mt="sm"
            mb="sm"
            icon={<IconAlertCircle size="1rem" />}
            title="Fehlende Daten"
            color="red"
            variant="filled"
          >
            Bitte gib einen Namen, eine Firma oder E-Mail-Adresse an für Partner.
          </Alert>
        )}
        {contactType === 'COMPANY' ? (
          <>
            <GoogleAutocomplete
              required
              label="Firma"
              onPlaceSelected={(place: any) => form.setValues((prev) => ({ ...prev, ...place }))}
              {...form.getInputProps('companyName')}
            />

            {/*<BookitupBadge title="E-Rechnung" mt="md" />*/}
            {/*<TextInput mt="sm" label={BuyerReferenceInputLabel} {...form.getInputProps('buyerReference')} />*/}
            {/*<Text mb="sm" color="dimmed" size="xs" mt={5}>*/}
            {/*  Sollte Dir keine Kundenreferenz vorliegen, gib einfach eine 0 an. Ist der Empfänger eine Behörde, nutze*/}
            {/*  die Leitweg-ID.*/}
            {/*</Text>*/}
          </>
        ) : (
          <>
            {CustomerNamePart}
            {showPartnerFields && (
              <>
                <BookitupBadge title="Partner" mt="md" />
                <Group grow spacing="xs" sx={{ alignItems: 'flex-start' }}>
                  <Autocomplete
                    label="Anrede"
                    mt="sm"
                    data={['Herr', 'Frau']}
                    {...form.getInputProps('partnerSalutation')}
                  />
                  <TextInput mt="sm" label="Vorname" {...form.getInputProps('partnerFirstName')} />
                  <TextInput mt="sm" label="Nachname" {...form.getInputProps('partnerLastName')} />
                </Group>
                <Text color="red" mt="sm" mb="xl" variant="link" sx={{ cursor: 'pointer' }} onClick={removePartner}>
                  - Partner löschen
                </Text>
              </>
            )}
            {!showPartnerFields && (
              <>
                <Text variant="link" mt="sm" sx={{ cursor: 'pointer' }} onClick={() => setAddingPartner(true)}>
                  + Partner hinzufügen
                </Text>
                {contactId && (
                  <Text
                    variant="link"
                    mt="xs"
                    mb="xl"
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      openSelectCreateContactModal(onPartnerContactSelect, contact.id ? [contact.id] : [], true)
                    }
                  >
                    + Vorhandenen Kontakt als Partner hinzufügen
                  </Text>
                )}
              </>
            )}
          </>
        )}
        <BookitupBadge title="Adresse" mt={form.values.contactType === 'COMPANY' || !contactId ? 'md' : 0} />
        <TextInput mt="sm" label="Adresszusatz" {...form.getInputProps('addressSupplement')} />
        <TextInput
          mt="sm"
          icon={<IconHome size={16} />}
          label="Straße und Hausnummer"
          {...form.getInputProps('addressStreetAndNo')}
        />
        <Group grow mt="xs">
          <TextInput
            required={presentBuyerReference}
            icon={<IconZip size={16} />}
            label="PLZ"
            {...form.getInputProps('zipCode')}
          />
          <TextInput
            required={presentBuyerReference}
            icon={<IconBuildingCommunity size={16} />}
            label="Stadt"
            {...form.getInputProps('city')}
          />
        </Group>
        <Select
          mt="sm"
          data={DataS.countryOptions}
          label="Land"
          styles={{ rightSection: { pointerEvents: 'none' } }}
          rightSection={<IconChevronDown size={14} />}
          rightSectionWidth={25}
          {...form.getInputProps('country')}
        />
        {contactType === 'COMPANY' && (
          <>
            <Badge mt="xl" radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }}>
              <Text weight="lighter"> Haupt-Ansprechpartner</Text>
            </Badge>
            {CustomerNamePart}
          </>
        )}
        {contactType === 'PRIVATE' && <BookitupBadge title="Kontaktinformation" mt="xl" />}
        <Group grow mt="sm">
          <TextInput icon={<IconPhone size={16} />} label="Telefonnummer" {...form.getInputProps('phoneNumber')} />
          <TextInput icon={<IconAt size={16} />} label="E-Mail-Adresse" {...form.getInputProps('email')} />
        </Group>
        <TextInput mt="sm" icon={<IconWorldWww size={16} />} label="Webseite" {...form.getInputProps('website')} />
        <TextInput
          mt="xs"
          mb="sm"
          icon={<IconPhonePlus size={16} />}
          label="Telefonnummer (alternativ)"
          {...form.getInputProps('phoneNumberAlt')}
        />
        {contactType === 'PRIVATE' && showPartnerFields && (
          <>
            <BookitupBadge title=" Partner Kontaktinformation" mt="md" />
            <Group grow mt="sm">
              <TextInput
                icon={<IconPhone size={16} />}
                label="Telefonnummer"
                {...form.getInputProps('partnerPhoneNumber')}
              />
              <TextInput icon={<IconAt size={16} />} label="E-Mail-Adresse" {...form.getInputProps('partnerEmail')} />
            </Group>
            <TextInput
              mt="xs"
              mb="sm"
              icon={<IconPhonePlus size={16} />}
              label="Telefonnummer (alternativ)"
              {...form.getInputProps('partnerPhoneNumberAlt')}
            />
          </>
        )}
        <ContactGroupSelection
          selectedGroups={form.values.groups}
          saveValue={(p: []) => form.setValues((prev) => ({ ...prev, groups: p }))}
        />
        {contactType === 'COMPANY' && contactId && <ContactRelations contactId={contactId} />}
        <Center>
          <Button mt="xl" type="submit" disabled={loading || !form.isDirty()} sx={{ minWidth: 250 }}>
            {i18n._('actions.save')}
          </Button>
        </Center>
      </form>
    </Box>
  );
};
