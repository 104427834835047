// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Loader, MultiSelect, Skeleton, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { IconBallpen, IconChevronDown } from '@tabler/icons';
import { EventsContext } from '../../../Service/Context/EventsContext';
import { useEventKinds } from '../../../Service/hooks/useEventKinds';
import { DataS } from '../../../Service/DataS';

const EventKindSelection = () => {
  const { filter, updateFilter } = useContext(EventsContext);
  const { kindsOfInterest } = filter;
  const { loading, kinds } = useEventKinds();
  const navigate = useNavigate();

  return (
    <>
      <Group position="apart" mt="xl" mb="xs">
        <Text pl={10}>Auftragsarten</Text>
        <div style={{ marginRight: 10 }}>
          <IconBallpen size={16} style={{ cursor: 'pointer' }} onClick={() => navigate('/settings/eventkinds')} />
        </div>
      </Group>
      {loading && <Skeleton width="100%" height={100} />}
      <MultiSelect
        searchable
        placeholder="Suchen"
        m="xs"
        data={DataS.getEventKindsOptions(kinds)}
        variant="filled"
        disabled={loading}
        icon={loading && <Loader size="xs" />}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        rightSection={<IconChevronDown size={14} />}
        value={kindsOfInterest}
        onChange={(value) => updateFilter({ kindsOfInterest: value })}
        rightSectionWidth={25}
      />
    </>
  );
};

export default EventKindSelection;
