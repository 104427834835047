import { DateUtils } from '../Utils/dateUtils';
import BookingState from './Enums/BookingState';
import { BookingState as EventState } from './MessageT';

export enum OutOfOrderType {
  REPAIR_USABLE = 'REPAIR_USABLE',
  REPAIR_NOT_USABLE = 'REPAIR_NOT_USABLE',
  ON_DELIVERY = 'ON_DELIVERY',
  SOLD = 'SOLD',
  LOST = 'LOST',
  VACATION = 'VACATION',
  SICK = 'SICK',
  CAR_SERVICE = 'CAR_SERVICE',
}

export enum PlanningState {
  CONFIRMED = 'CONFIRMED',
  PREPPED = 'PREPPED',
  ON_LOCATION = 'ON_LOCATION',
  RETURNED = 'RETURNED',
}

export enum ResourceType {
  ITEM = 'ITEM',
  KIT = 'KIT',
  CASE = 'CASE',
  HUMAN = 'HUMAN',
  VEHICLE = 'VEHICLE',
}

export enum ResourceUsage {
  RENTAL = 'RENTAL',
  SALE = 'SALE',
  CONSUMABLE = 'CONSUMABLE',
}

export type Exemplar = {
  id: string;
  resource: Resource;
  serialNumber: string;
};

export type OutOfOrder = {
  id: string;
  resourceId: string;
  exemplarId: string;
  quantity: number;
  begin: Date;
  end: Date;
  type: OutOfOrderType;
  reportedBy: number;
  assignedTo: number;
  remark: string;
};

export type CreatePart = {
  resourceId: string;
  quantity: number;
};

export type Part = {
  id: string;
  resource: Resource;
  partOfResourceId: string;
  quantity: number;
};

export type Planning = {
  id: string;
  resource: Resource;
  plannedQuantity: number;
  plannedWarehouseQuantity: number; // TODO: Remove
  exemplar?: Exemplar;
  planningGroup?: PlanningGroup;
  warehousePlanning?: WarehousePlanning;
  children?: Planning[];
};

export type EventPlanning = Omit<Planning, 'children'> & {
  shortages: Shortage[];
  children: EventPlanning[];
  warehousePlannings: WarehousePlanning[];
};

export type DefaultPlanning = Pick<Planning, 'id' | 'resource' | 'plannedQuantity' | 'exemplar'> & {
  planningGroupId?: string;
  children: DefaultPlanning[];
};

export type CreatePlanning = Omit<Planning, 'id' | 'resource' | 'exemplar' | 'parentResource' | 'planningGroup'> & {
  resourceId: string;
  entityType: string;
  entityId: string;
  exemplarId?: string;
  planningGroupId?: string;
};

export type CreateDefaultPlanning = {
  resourceId: string;
  plannedQuantity: number;
  planningGroupId?: string;
  exemplarId?: string;
};

export type PlanningGroup = {
  id: string;
  description: string;
  entityType: string;
  entityId: string;
  begin: Date;
  end: Date;
  usageBegin: Date;
  usageEnd: Date;
};

export type CreatePlanningGroup = Omit<PlanningGroup, 'id'>;

export type Resource = {
  id: string;
  name: string;
  code: string;
  description: string;
  type: ResourceType;
  usage: ResourceUsage;
  category?: Omit<ResourceCategory, 'parent'>;
  content: Part[];
  archive: boolean;
};

export type ResourceAccessory = {
  id: string;
  ownerId: string;
  resource: Resource;
  quantity: number;
};

export type ResourceAlternative = {
  id: string;
  ownerId: string;
  resource: Resource;
};

export type CreateResourceAccessory = {
  quantity: number;
  resourceId: string;
};

export type CreateResourceAlternative = {
  resourceId: string;
};

export type ResourceUpdate = Partial<
  Pick<Resource, 'name' | 'description' | 'type' | 'usage' | 'code' | 'archive'> & { categoryName: string | null }
>;

export type ResourceRequest = Omit<Resource, 'id' | 'ownerIds' | 'content' | 'code' | 'archive'>;

export type Shortage = {
  uuid: string;
  begin: Date;
  end: Date;
  resourceId: string;
  entityType?: string;
  entityId?: string;
  own: number;
  resourceType: ResourceType;
  showResourcePlanningShortage: boolean;
  showWarehousePlanningShortage: boolean;
  inCase: number;
  reserved: number;
  planned: number;
  inRepair: number;
  subRented: number;
  shortage: number;
  warehouseShortage: number;
  shortageAfterSubRental: number;
};

export type WarehousePlanningUpdate = Omit<WarehousePlanning, 'id'> & { containerId?: string };

export type WarehousePlanning = {
  id: string;
  resourcePlanningId: string;
  quantity: number;
  state: PlanningState;
};

export interface EventDateDto {
  id: number;
  date: Date;
  timeFrom: Date;
  timeTo: Date;
  hours: number;
  lastModified: Date;
}

export interface AvailabilityCheckDto {
  eventId: number;
  dates: EventDateDto[];
  ignoreBefore: Date;
  ignoreAfter: Date;
}

export interface CalendarEventsDto {
  id: string;
  profileId: number;
  date: Date;
  privateEvents: EventDateDto[];
  requestId: string;
}

export type StockIntervalDto = {
  id: string;
  resourceId?: string;
  begin: Date;
  description: string;
  quantityChange: number;
};

export type CreateStockIntervalDto = Omit<StockIntervalDto, 'id'>;

export type ResourceCategory = {
  id: string;
  name: string;
  parentId?: string;
  parentName?: string;
  children: ResourceCategory[];
};

export interface UsageDTO {
  owner: Resource;
  quantity: number;
}

export type CreateResourceCategory = Omit<ResourceCategory, 'id'>;

export type PlanningCountReportDTO = {
  pastPlanningsCount: number;
  futurePlanningsCount: number;
};

export type ResourcePlanningDTO = {
  id: string;
  quantity: number;
  begin: Date;
  end: Date;
  eventId: string;
  warehouseStatus: PlanningState | null;
  shortages: Shortage[];
  children: ResourcePlanningDTO[];
};

export type ResourceFilter = {
  pageNumber: number;
  pageSize: number; // TODO: Set fixed pageSize instead??
  withContent: boolean;
  query?: string;
  showArchived?: boolean;
  categoriesOfInterest: string[];
  typesOfInterest: string[];
  trigger?: Date; // Dummy prop for refreshing
};

export type BaseResourceFilter = {
  pageNumber: number;
  pageSize: number;
  query?: string;
  categoriesOfInterest: string[];
  typesOfInterest: string[];
};

export interface TableResponsiveProps {
  showCategory?: boolean;
  showType?: boolean;
  showDescription?: boolean;
  showCounter?: boolean;
  showPreview?: boolean;
  showCalUrl?: boolean;
  showLastModified?: boolean;
  showClashesIcon?: boolean;
}

export interface DateInterval {
  start: Date;
  end: Date;
}

export type EventTimelineItem = {
  id: number;
  eventId: number;
  bookingState: BookingState;
  title: string;
  start: Date;
  end: Date;
  kind: string | null;
  planningGroups: EventPlanningGroup[];
};

export type EventPlanningGroup = {
  id: string;
  name: string;
  interval: DateInterval;
  planningsCount: number;
  warehousePlanningsCount: number;
  showResourcePlanningShortage: boolean;
  showWarehousePlanningShortage: boolean;
};

export interface EventPlanningGroups {
  [eventId: string]: EventPlanningGroup[];
}

export interface ContentInterface<T, X> {
  items: T[];
  loading: boolean;
  filter: X;
  updateFilter: (patch: Partial<X>) => unknown;
  totalCount: number;
  networkError: boolean;
}

export type TimelineFilter = {
  from: Date;
  to: Date;
  statesOfInterest: EventState[];
};

export const DefaultTimelineFilter: TimelineFilter = {
  from: DateUtils.TimelineDefaultFilterStart,
  to: DateUtils.TimelineDefaultFilterEnd,
  statesOfInterest: ['OPEN', 'OFFERED', 'BOOKED'],
};

export type AddonDTO = {
  trialActivated: boolean;
  active: boolean;
};

export interface AvailabilityFilter {
  from: Date;
  to: Date;
}
