/* eslint-disable import/prefer-default-export */
import BookitupDocument from '../../Types/BookitupDocument';
import { Contact } from '../ContactS';
import { useOwnContact } from '../Context/ContactContext';
import { useOwnProfile } from '../Context/ProfileContext';
import { ValidationS, isDefined } from '../ValidationS';
import { DocumentS } from '../../Templates/Documents/DocumentS';
import { useBankAccount } from './useBankAccount';

export const useXRechnungValidator = (invoice: BookitupDocument, customer?: Contact | null) => {
  const { profile } = useOwnProfile();
  const { contact } = useOwnContact();
  const { bankAccount } = useBankAccount();
  const { validUntil, shippingDate, documentType } = invoice;
  const xrechnungRequired = customer && isDefined(customer.buyerReference);
  const isInvoice = DocumentS.isInvoice(documentType);
  const { smallScaleBusiness } = profile ?? {};

  if (!isInvoice || !xrechnungRequired || !contact || !bankAccount || !profile) return { valid: true };

  const validAddress = ValidationS.verifyAddressInformation(contact);
  const validBuyerAddress = customer && isDefined(customer.zipCode) && isDefined(customer.city);
  const validTaxFields = ValidationS.verifyTaxFields(profile);
  const validBankAccount = ValidationS.verifyBankAccount(bankAccount);
  const validCompanyName = customer && isDefined(customer.companyName);
  const validDocumentFields = validUntil !== null && shippingDate !== null;
  const buyerEmailProvided = customer === undefined || (customer && isDefined(customer.email));
  const negativePositionPrice = invoice.positions.find((p) => p.netPrice < 0) !== undefined;
  const validTaxType = invoice.taxType === 'net' || smallScaleBusiness;

  const valid =
    validAddress &&
    validBuyerAddress &&
    validTaxFields &&
    validBankAccount &&
    validCompanyName &&
    validDocumentFields &&
    buyerEmailProvided &&
    !negativePositionPrice &&
    validTaxType;

  return {
    valid,
    validAddress,
    validBuyerAddress,
    validTaxFields,
    validBankAccount,
    validCompanyName,
    validDocumentFields,
    buyerEmailProvided,
    negativePositionPrice,
    validTaxType,
  };
};
