// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Checkbox, Group, Loader, NumberInput, Select, Text, TextInput } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { ChronoUnit, CreateTaskConfigDTO, ExecutionStrategy, WorkflowConfigProps } from '../../../../../Types/LogT';
import { fetchTaskGroups } from '../../../../../Service/restapi/taskService';
import { DataS } from '../../../../../Service/DataS';
import AgendaGroup from '../../../../../Types/AgendaGroup';

const CreateTaskConfig: FC<WorkflowConfigProps> = ({ config, handleChange }) => {
  const { name, groupName, priority, saveUnderEvent, notes, relativeAmount, relativeUnit, dueDateStrategy } =
    config as CreateTaskConfigDTO;
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<string[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchTaskGroups(saveUnderEvent ? 'events' : 'profiles')
      .then((res) => {
        if (res.ok) {
          res.json().then((resJSON) => {
            const groupNames: string[] = resJSON.map((g: AgendaGroup) => g.name);
            if (!groupNames.includes(groupName)) {
              groupNames.push(groupName);
            }
            setGroups(groupNames);
          });
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveUnderEvent]);

  return (
    <>
      <Group grow>
        <TextInput
          required
          label="Name"
          defaultValue={name}
          onBlur={(e) => handleChange({ name: e.currentTarget.value })}
        />
        <Select
          creatable
          searchable
          required
          label="Gruppe"
          data={groups}
          defaultValue={groupName ?? ''}
          onBlur={(e) => handleChange({ groupName: e.currentTarget.value })}
          rightSection={loading && <Loader size="xs" />}
          getCreateLabel={(query) => (
            <Text color="blue">
              {' '}
              + Hinzufügen{' '}
              <Text span color="black" ml={10} weight="bolder">
                {query}
              </Text>
            </Text>
          )}
          onCreate={(query) => {
            setGroups([...groups, query]);
            return query;
          }}
        />
      </Group>
      <TextInput
        mt="xs"
        label="Beschreibung"
        defaultValue={notes}
        onBlur={(e) => handleChange({ notes: e.currentTarget.value })}
      />
      <Text mt="xs" sx={(theme) => ({ color: theme.colors.gray[9] })}>
        Erledigen bis
      </Text>
      <Group spacing={5}>
        <NumberInput
          hideControls
          defaultValue={relativeAmount}
          min={0}
          sx={{ width: 50 }}
          onBlur={(e) => handleChange({ relativeAmount: Number(e.currentTarget.value) })}
        />
        <Select
          defaultValue={relativeUnit}
          data={DataS.getChronoUnitsData(i18n)}
          sx={{ width: 100 }}
          onChange={(val: ChronoUnit) => val && handleChange({ relativeUnit: val })}
        />
        <Select
          sx={{ flexGrow: 1 }}
          defaultValue={dueDateStrategy}
          data={DataS.getExecutionStrategyOptions(i18n)}
          onChange={(val: ExecutionStrategy) => val && handleChange({ dueDateStrategy: val })}
        />
      </Group>
      <Checkbox
        size="xs"
        mt="sm"
        defaultChecked={priority}
        label="Als wichtige Aufgabe markieren"
        onChange={(e) => handleChange({ priority: e.currentTarget.checked })}
      />
      <Checkbox
        size="xs"
        defaultChecked={saveUnderEvent}
        mt="xs"
        label="Aufgabe in Event speichern"
        onChange={(e) => handleChange({ saveUnderEvent: e.currentTarget.checked, groupName: null })}
      />
    </>
  );
};

export default CreateTaskConfig;
