/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import { closeAllModals, openModal } from '@mantine/modals';
import { Button, Center, Checkbox, ColorInput, Group, Loader, Text, TextInput } from '@mantine/core';
import { FC, useState } from 'react';
import { i18n } from '@lingui/core';
import { useForm } from '@mantine/form';
import { IconCalendarPlus } from '@tabler/icons';
import { ExternalSubscription } from '../../../../Types/CalDavT';
import { CalDavS } from '../../../../Service/CalDavS';
import Dot from '../../../../Atoms/Dot';

interface OpenModalProps {
  onCreate: (subscription: ExternalSubscription) => void;
}

export const openCreateExternalCalModal = (props: OpenModalProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        🗓️ Kalender hinzufügen
      </Text>
    ),
    children: <CreateExternalCalModal {...props} />,
  });

const CreateExternalCalModal: FC<OpenModalProps> = ({ onCreate }) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');

  const create = async () => {
    setLoading(true);
    const iCal = await CalDavS.createExternalCal(url);
    setLoading(false);
    if (iCal) {
      closeAllModals();
      onCreate(iCal);
    }
  };

  return (
    <>
      <TextInput label="Öffentliche Kalender-URL" value={url} onChange={(e) => setUrl(e.currentTarget.value)} />
      <Text mt={5} mb="xl" color="dimmed" size="xs">
        Wichtig: Stelle sicher, dass die Sichtbarkeit Deines Kalenders auf „öffentlich“ eingestellt ist. Sonst kann bookitup Deinen Kalender nicht anzeigen.
      </Text>
      <Center>
        <Button
          leftIcon={<IconCalendarPlus />}
          disabled={url === '' || loading}
          onClick={create}
          rightIcon={loading && <Loader size="xs" />}
        >
          {i18n._('actions.add')}
        </Button>
      </Center>
    </>
  );
};

interface EditModalProps {
  subscription: ExternalSubscription;
  onChange: (subscription: ExternalSubscription) => void;
}

export const openEditExternalCalModal = (props: EditModalProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        🗓️ Kalender bearbeiten
      </Text>
    ),
    children: <EditExternalCalModal {...props} />,
  });

const EditExternalCalModal: FC<EditModalProps> = ({ subscription, onChange }) => {
  const [loading, setLoading] = useState(false);
  const { id, color, name, checkForClashes } = subscription;

  const form = useForm({
    initialValues: {
      color,
      name,
      checkForClashes,
    },
    validate: {
      color: (value) => (value !== '' ? null : 'Bitte Farbe eingeben'),
      name: (value) => (value.length > 0 ? null : 'Bitte Etikett eingeben'),
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    const iCal = await CalDavS.editExternalCal(id, { ...form.values });
    setLoading(false);
    if (iCal) {
      onChange(iCal);
      closeAllModals();
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <TextInput mt="sm" label="Name" {...form.getInputProps('name')} />
      <ColorInput mt="sm" mb="md" label="Farbe" {...form.getInputProps('color')} />
      <Checkbox
        label="Überprüfen Sie den Kalender auf Verfügbarkeit"
        {...form.getInputProps('checkForClashes', { type: 'checkbox' })}
      />
      <Center mt="xl">
        <Button
          type="submit"
          disabled={loading || !form.isDirty()}
          rightIcon={loading && <Loader size="xs" />}
          sx={{ minWidth: 200 }}
        >
          {i18n._('actions.edit')}
        </Button>
      </Center>
    </form>
  );
};

interface DeleteModalProps {
  subscription: ExternalSubscription;
  onDelete: () => void;
}

export const openDeleteExternalCalModal = (props: DeleteModalProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        🗓️ Kalender hinzufügen
      </Text>
    ),
    children: <DeleteExternalCalModal {...props} />,
  });

const DeleteExternalCalModal: FC<DeleteModalProps> = ({ subscription, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const { id, name, color } = subscription;

  const onConfirm = async () => {
    setLoading(true);
    const ok = await CalDavS.deleteExternalCal(id);
    if (ok) {
      onDelete();
      closeAllModals();
    }
    setLoading(false);
  };

  return (
    <>
      <Text>Möchten Sie den folgenden Kalender wirklich entfernen?</Text>
      <Group m="md">
        <Dot withoutShadow colour={color} />
        <Text weight="bolder">{name}</Text>
      </Group>
      <Group position="right" spacing="xs" mt="xl">
        <Button disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          color="red"
          variant="subtle"
          sx={{ backgroundColor: '#FFEFEF' }}
          onClick={onConfirm}
          disabled={loading}
          leftIcon={loading && <Loader size="xs" />}
        >
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};
