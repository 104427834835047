import { i18n } from '@lingui/core';
import { Group, Skeleton, Space, Text } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import { IconBallpen } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { BookingStateIcon } from '../../../Atoms/StateIcon';
import BookingState from '../../../Types/Enums/BookingState';
import BookingStateItem from '../BookingStateItem';
import { EventsContext } from '../../../Service/Context/EventsContext';
import { useAppUser } from '../../../Service/Context/ApplicationUserContext';
import { CustomEventState } from '../../../Types/EventT';
import { LicenceS } from '../../../Service/LicenceS';
import { useJwtClaims } from '../../../Service/Context/AuthContext';
import { EventS } from '../../../Service/EventS';

const ListBookingStateSelection = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { filter, updateFilter } = useContext(EventsContext);
  const { redGreenWeakness } = useAppUser();
  const [eventStates, setEventStates] = useState<CustomEventState[]>([]);
  const { licence } = useJwtClaims();
  const customStatesAvailable = LicenceS.hasSuitableLicence('INTERACTIVE', licence);
  const { agencyModeActive, statesOfInterest, recommendationBookingStates } = filter;

  useEffect(() => {
    if (customStatesAvailable) {
      fetchStates();
    }
  }, [customStatesAvailable]);

  const fetchStates = async () => {
    setLoading(true);
    const _eventStates = await EventS.fetchEventStates();
    setEventStates(_eventStates);
    setLoading(false);
  };

  const toggleState = (state: BookingState) => {
    let newState = Object.assign([], statesOfInterest);

    if (state === 'OPEN' || state === 'OFFERED' || state === 'BOOKED') {
      if (newState.includes(state)) {
        newState = newState.filter((s: any) => s !== state);
        updateFilter({ statesOfInterest: newState });
      } else {
        newState.push(state);
        updateFilter({ statesOfInterest: newState });
      }
    } else if (newState.includes(state)) {
      newState = newState.filter((s: any) => s !== state);
      updateFilter({ statesOfInterest: newState });
    } else {
      newState.push(state);
      updateFilter({ statesOfInterest: newState });
    }
  };

  const toggleRecommendationState = (state: BookingState) => {
    let newState = Object.assign([], recommendationBookingStates);

    if (state === 'OPEN' || state === 'OFFERED' || state === 'BOOKED') {
      if (newState.includes(state)) {
        newState = newState.filter((s: any) => s !== state);
        updateFilter({ recommendationBookingStates: newState });
      } else {
        newState.push(state);
        updateFilter({ recommendationBookingStates: newState });
      }
    } else if (newState.includes(state)) {
      newState = newState.filter((s: any) => s !== state);
      updateFilter({ recommendationBookingStates: newState });
    } else {
      newState.push(state);
      updateFilter({ recommendationBookingStates: newState });
    }
  };

  return (
    <>
      <Space h="xl" />
      <Group position="apart">
        <Text pl={10}>Buchungsstatus</Text>
        {customStatesAvailable && (
          <div style={{ marginRight: 10 }}>
            <IconBallpen size={16} style={{ cursor: 'pointer' }} onClick={() => navigate('/settings/eventstates')} />
          </div>
        )}
      </Group>
      <Space h="xs" />
      {customStatesAvailable && loading && <Skeleton width="100%" height={200} />}
      {customStatesAvailable && (
        <>
          <EventStateFilterItems toggleState={toggleState} bookingState={BookingState.OPEN} states={eventStates} />
          <EventStateFilterItems toggleState={toggleState} bookingState={BookingState.OFFERED} states={eventStates} />
          <EventStateFilterItems toggleState={toggleState} bookingState={BookingState.BOOKED} states={eventStates} />
          <EventStateFilterItems
            toggleState={toggleState}
            bookingState={BookingState.UNAVAILABLE}
            states={eventStates}
          />
          <EventStateFilterItems toggleState={toggleState} bookingState={BookingState.CANCELED} states={eventStates} />
        </>
      )}
      {!customStatesAvailable && (
        <>
          <BookingStateItem
            label={i18n._('event.state.open')}
            color="#1D5191"
            bgColor="#E8EEF4"
            onClick={() => toggleState(BookingState.OPEN)}
            active={statesOfInterest.includes(BookingState.OPEN)}
            icon={redGreenWeakness && <BookingStateIcon bookingState={BookingState.OPEN} size={16} />}
          />
          <BookingStateItem
            label={i18n._('event.state.offered')}
            color="#FDA632"
            bgColor="#FFF6EB"
            onClick={() => toggleState(BookingState.OFFERED)}
            active={statesOfInterest.includes(BookingState.OFFERED)}
            icon={redGreenWeakness && <BookingStateIcon bookingState={BookingState.OFFERED} size={16} />}
          />
          <BookingStateItem
            label={i18n._('event.state.booked')}
            color="#4EBF46"
            bgColor="#EDF9ED"
            onClick={() => toggleState(BookingState.BOOKED)}
            active={statesOfInterest.includes(BookingState.BOOKED)}
            icon={redGreenWeakness && <BookingStateIcon bookingState={BookingState.BOOKED} size={16} />}
          />
          <BookingStateItem
            label={i18n._('event.state.unavailable')}
            color="#757575"
            bgColor="#eeeeee"
            onClick={() => toggleState(BookingState.UNAVAILABLE)}
            active={statesOfInterest.includes(BookingState.UNAVAILABLE)}
            icon={redGreenWeakness && <BookingStateIcon bookingState={BookingState.UNAVAILABLE} size={16} />}
          />
          <BookingStateItem
            label={i18n._('event.state.canceled')}
            color="#f44336"
            bgColor="#ffebee"
            onClick={() => toggleState(BookingState.CANCELED)}
            active={statesOfInterest.includes(BookingState.CANCELED)}
            icon={redGreenWeakness && <BookingStateIcon bookingState={BookingState.CANCELED} size={16} />}
          />
        </>
      )}

      {agencyModeActive && (
        <>
          <Space h="xl" />
          <Text pl={10} pb="xs">
            Vermittlungsstatus
          </Text>
          <BookingStateItem
            label={i18n._('event.state.open')}
            color="#1D5191"
            bgColor="#E8EEF4"
            onClick={() => toggleRecommendationState(BookingState.OPEN)}
            active={recommendationBookingStates.includes(BookingState.OPEN)}
            icon={redGreenWeakness && <BookingStateIcon bookingState={BookingState.OPEN} size={16} />}
          />
          <BookingStateItem
            label={i18n._('event.state.offered')}
            color="#FDA632"
            bgColor="#FFF6EB"
            onClick={() => toggleRecommendationState(BookingState.OFFERED)}
            active={recommendationBookingStates.includes(BookingState.OFFERED)}
            icon={redGreenWeakness && <BookingStateIcon bookingState={BookingState.OFFERED} size={16} />}
          />
          <BookingStateItem
            label={i18n._('event.state.booked')}
            color="#4EBF46"
            bgColor="#EDF9ED"
            onClick={() => toggleRecommendationState(BookingState.BOOKED)}
            active={recommendationBookingStates.includes(BookingState.BOOKED)}
            icon={redGreenWeakness && <BookingStateIcon bookingState={BookingState.BOOKED} size={16} />}
          />
          <BookingStateItem
            label={i18n._('event.state.unavailable')}
            color="#757575"
            bgColor="#eeeeee"
            onClick={() => toggleRecommendationState(BookingState.UNAVAILABLE)}
            active={recommendationBookingStates.includes(BookingState.UNAVAILABLE)}
            icon={redGreenWeakness && <BookingStateIcon bookingState={BookingState.UNAVAILABLE} size={16} />}
          />
          <BookingStateItem
            label={i18n._('event.state.canceled')}
            color="#f44336"
            bgColor="#ffebee"
            onClick={() => toggleRecommendationState(BookingState.CANCELED)}
            active={recommendationBookingStates.includes(BookingState.CANCELED)}
            icon={redGreenWeakness && <BookingStateIcon bookingState={BookingState.CANCELED} size={16} />}
          />
        </>
      )}
    </>
  );
};

interface Props {
  bookingState: BookingState;
  states: CustomEventState[];
  toggleState: (state: BookingState) => void;
}

const EventStateFilterItems: FC<Props> = ({ bookingState, states, toggleState }) => {
  const { filter, updateFilter } = useContext(EventsContext);
  const { redGreenWeakness } = useAppUser();
  const eventStates = _.orderBy(
    states.filter((s) => s.originState === bookingState),
    ['idx'],
  );
  const { statesOfInterest, customStatesOfInterest } = filter;

  const toggleCustomState = (id: string) => {
    let newState: string[] = Object.assign([], customStatesOfInterest);
    if (customStatesOfInterest.includes(id)) {
      newState = newState.filter((s) => s !== id);
    } else {
      newState.push(id);
    }
    updateFilter({ customStatesOfInterest: newState });
  };

  const isActive = (state: CustomEventState) => {
    const { id, originState } = state;
    if (id) {
      return customStatesOfInterest.includes(id);
    }
    return statesOfInterest.includes(originState);
  };

  const onClick = (state: CustomEventState) => {
    if (state.id) {
      toggleCustomState(state.id);
    } else {
      toggleState(state.originState);
    }
  };

  const getIcon = (state: CustomEventState) => {
    const { emoji, originState } = state;
    if (emoji) {
      return emoji;
    }
    if (redGreenWeakness) {
      return <BookingStateIcon bookingState={originState} size={16} />;
    }
    return null;
  };

  return (
    <>
      {eventStates.map((state) => {
        const { id, label, color, bgColor, originState } = state;
        return (
          <BookingStateItem
            key={id ?? originState}
            label={label}
            color={color}
            bgColor={bgColor}
            onClick={() => onClick(state)}
            active={isActive(state)}
            icon={getIcon(state)}
          />
        );
      })}
    </>
  );
};

export default ListBookingStateSelection;
