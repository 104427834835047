import { useListState } from '@mantine/hooks';
import { useState, useEffect } from 'react';
import { EventKind } from '../../Types/EventT';
import { EventS } from '../EventS';

// eslint-disable-next-line import/prefer-default-export
export const useEventKinds = () => {
  const [loading, setLoading] = useState(true);
  const [kinds, handlers] = useListState<EventKind>([]);
  const { setState, append, applyWhere, filter } = handlers;
  const kindNames = kinds.map((_kind) => _kind.name);
  const { length } = kinds;

  useEffect(() => {
    EventS.fetchEventKinds()
      .then(setState)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateKind = (kind: EventKind) =>
    applyWhere(
      (k) => k.id === kind.id,
      () => kind,
    );

  const deleteKind = (id: string) => filter((kind) => kind.id !== id);

  return { loading, kinds, kindNames, length, append, updateKind, deleteKind };
};
